import React, { useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';

const SiteMap = () => {

    useEffect(() => {
        document.title = 'Site-map | Elite Site Optimizer';
    }, []);

    return (
        <div id="et-main-area">
            <div className="terms_pages">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="common_text_inner top_padding_50 bottom_padding_50">
                                <h1 style={{}} className="common_center bottom_padding_30 static_map" id="site-1">Site Map</h1>
                                <h2 style={{ textAlign: 'left' }} className="static_map" id="site-2">Pages List</h2>
                                <div>
                                    <div className="site-map">
                                        <ul className="lists arrow-list">
                                            <li><HashLink title="Home" to="/">Home</HashLink></li>
                                            <li><a title="Solutions" href="#">Solutions</a>
                                                <ul className="sub-menu">
                                                    <li><a title="Ecommerce Business" href="https://www.elitesiteoptimizer.com/solutions/ecommerce-business">Ecommerce Business</a></li>
                                                    <li><a title="Restaurant SEO" href="https://www.elitesiteoptimizer.com/solutions/restaurants-seo">Restaurant SEO</a></li>
                                                    <li><a title="Digital Marketing" href="https://www.elitesiteoptimizer.com/solutions/digital-marketing">Digital Marketing</a></li>
                                                    <li><a title="Enterprise Brands" href="https://www.elitesiteoptimizer.com/solutions/enterprise-brands">Enterprise Brands</a></li>
                                                    <li><a title="B2G SEO" href="https://www.elitesiteoptimizer.com/solutions/b2g-seo">B2G SEO</a></li>
                                                    <li><a title="Personal Bloggers" href="https://www.elitesiteoptimizer.com/solutions/personal-bloggers">Personal Bloggers</a></li>
                                                    <li><a title="News & Content Publishers" href="https://www.elitesiteoptimizer.com/solutions/news-content-publishers">News &amp; Content Publishers</a></li>
                                                    <li><a title="Web Hosting Seo Toolkit" href="https://www.elitesiteoptimizer.com/solutions/web-hosting">Web Hosting Seo Toolkit</a></li>
                                                </ul>
                                            </li>
                                            <li><HashLink title="Services" to="/services" elementId='root'>Services</HashLink>
                                                <ul className="sub-menu">
                                                    <li><HashLink title="Digital Marketing services" to="/services/digital-marketing-services/" elementId='root'>Digital Marketing</HashLink>
                                                        <ul className="sub-menu">
                                                            <li><HashLink title="Performance based SEO services" to="/services/digital-marketing-services/performance-based-seo/" elementId='root'>Performance Based SEO</HashLink></li>
                                                            <li><HashLink title="Mobile SEO services" to="/services/digital-marketing-services/mobile-seo/" elementId='root'>Mobile SEO</HashLink></li>
                                                            <li><HashLink title="App Store optimization services" to="/services/digital-marketing-services/app-store-optimization/" elementId='root'>App Store Optimization</HashLink></li>
                                                        </ul>
                                                    </li>
                                                    <li><HashLink to="/services/custom-crawler/" elementId='root'>Custom Crawler</HashLink></li>
                                                </ul>
                                            </li>
                                            <li><HashLink title="Features" to="/features/">Features</HashLink>
                                                <ul className="sub-menu">
                                                    <li><HashLink title="Site Audit" to="/features/seo-dashboard/" elementId='root'>SEO Dashboard</HashLink></li>
                                                    <li><HashLink title="Webpage performance analysis" to="/features/webpage-seo-analysis/" elementId='root'>Performance Analysis</HashLink></li>
                                                    <li><HashLink title="Rank analysis" to="/features/keyword-ranking-checker/" elementId='root'>Rank Analysis</HashLink></li>
                                                    <li><HashLink title="On Page SEO Analysis" to="/features/on-page-seo-analysis/" elementId='root'>On-Page SEO Analysis</HashLink></li>
                                                    <li><HashLink title="Link Checker" to="/features/internal-link-checker/" elementId='root'>Link Checker</HashLink></li>
                                                    <li><HashLink to="/features/schema-validator/" elementId='root'>Schema Validator</HashLink></li>
                                                    <li><HashLink title="Analytics Console" to="/features/traffic-analytics/" elementId='root'>Analytics Console</HashLink></li>
                                                    <li><HashLink to="/features/sitemap-generator/" elementId='root'>Sitemap Generator</HashLink></li>
                                                    <li><HashLink to="/features/content-audit/" elementId='root'>Content Audit</HashLink></li>
                                                </ul>
                                            </li>
                                            <li><HashLink title="Plans & Pricing" to="#" elementId='root'>Pricing </HashLink>
                                                <ul className="sub-menu">
                                                    <li><HashLink title="Plans & Pricing" to="/plans-and-pricing/" elementId='root'>Elite Site Optimizer Inc.</HashLink></li>
                                                    <li><HashLink title="Plans & Pricing" to="/seo-tools/">SEO Tools</HashLink></li>
                                                </ul>
                                            </li>
                                            <li><HashLink title="Blog" to="/blog" elementId='root'>Blogs</HashLink></li>
                                            <li><HashLink title="About Us" to="/about" elementId='root'>About Us</HashLink></li>
                                            <li><HashLink title="Contact Us" to="/contact-us" elementId='root'>Contact Us</HashLink></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default SiteMap;