import React, { useState, useEffect } from 'react';
import axios from 'axios';
import parse from 'html-react-parser';
import { HashLink } from 'react-router-hash-link';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link, useParams } from "react-router-dom";
// import parseRange from 'http-range-parse';
import './blog.css';
import './hfstyle.css';
import EditorsPick from './staticTopics/EditorsPick';
import HottestTopics from './staticTopics/HottestTopics';
import LatestTopics from './staticTopics/LatestTopics';
// import 'bootstrap/dist/css/bootstrap.min.css';

const Blog = () => {

    useEffect(() => {
        document.title = 'SEO Audit &amp; Reporting Tool | SEO Automation Tool | Official Blog';
        window.scrollTo(0, 0)
        
    }, []);

    
    const [countOne, setCountOne] = useState(0)
    const [countTwo, setCountTwo] = useState(6)
    const [token, setToken] = useState()
    const [cardData, setCardData] = useState([])
    // const [editorsPick, setEditorsPick] = useState([])
    // const [hottestPick, setHottestPick] = useState([])
    // const [lattestPick, setLatestPick] = useState([])
    const [search, setSearch] = useState('')
    const [showData, setShowData] = useState(true)
    const [searchTitle, setSearchTitle] = useState("Editor’s pick")
    const [searchData, setSearchData] = useState([])
    const [showSearchData, setShowSearchData] = useState(false)
    const [resultData, setResultData] = useState([])
    const [sliceCountOne, setSliceCountOne] = useState(0)
    const [sliceCountTwo, setSliceCountTwo] = useState(6)
    
    // const [detailUrl, setDetailUrl] = useState()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const location = useLocation();
    
    const urlState = location.state
    
    const apiUrl = `/auth/local`;
    // console.log(urlState)
    
    const apiUrlTwo = `${process.env.REACT_APP_BLOGS_API}/blogs?publish_status=Publish&_sort=date_time_publish:DESC&_start=${countOne}&_limit=${countTwo}`
    
    const searchApi = `${process.env.REACT_APP_BLOGS_API}/blogs?publish_status=Publish&_sort=date_time_publish:DESC&_start=${0}&_limit=${200}`
    // const searchApiUnlimited =`/blogs?publish_status=Publish&_sort=date_time_publish:DESC&_start=${0}&_limit=${6}`
    // const apiUrlForPage = `/blogs?publish_status=Publish&url_slug=${}`
    // const api = `/blogs?publish_status=Publish&_sort=date_time_publish:DESC&_limit=4&url_slug_ne=why-seo-is-important-for-staffing-agencies`
    //https://strapi.egrovetech.com/blogs?publish_status=Publish&_sort=date_time_publish:DESC&_start=${0}&_limit=${200}
    

    const dataParams = {
        identifier: 'admin',
        password: 'admin@123!'
    }

    const loadMore = async () => {
        setLoading(true);
        setCountOne(countOne + 6)
        setCountTwo(countTwo + 6)
        
        const dataParams = {
            token: `${process.env.REACT_APP_STRAPI_AUTH_TOKEN}`,
            countOne: countOne,
            countTwo: countTwo
        }
        await axios.post(`${process.env.REACT_APP_DOMAIN}/call-blogs-loadmore-data`, dataParams).then((res) => { setCardData(res.data) })
  
        setLoading(false)
    }

    const handleChange = (e) => {
        setSearch(e.target.value);
        // callSearchApi()
    }

    const callSearchApi = async() => {
        await axios.get(searchApi).then((res) => { setSearchData(res.data)})
    }

    useEffect(() => {
        callSearchApi()
    }, [])

    

    console.log(search)
    const submit = () => {
        // console.log(search)
        setSearchTitle(search)
        
        if (search === '') {
            setShowData(true)
            setShowSearchData(false)
            console.log('submit if case')
        } else {
            setShowData(false)
            setShowSearchData(true)
            callSearchApi()
            const showableData = searchData.filter(searchValue => searchValue.title.toLowerCase().includes(search.toLowerCase()))
            const setLimit = showableData.map((items, index) => { return items })
            setResultData(setLimit)
            console.log('submit else case')
        }
        
        // await axios.get(searchApi).then((res) => { setSearchData(res.data)})
        if (searchData.length === 0){
            console.log('empty')
            submit()
        } else {
            // console.log('searchdata', searchData)
            console.log('exit')
        }
        // const showableData = searchData.filter(searchValue => searchValue.title.toLowerCase().includes(search))
        // const setLimit = showableData.slice(0, 6).map((items, index) => { return items })
        // console.log(showableData)
        // setResultData(searchData.map((items, index) => { return items }))
        // console.log(searchData)
    }

    

    

    
    

    const [searchDataTwo, setSearchDataTwo] = useState([])
    const [loadMoreResultData, setLoadMoreResultData] = useState([])

    const loadMoreSearch = async () =>{
        await axios.get(searchApi).then((res) => { setSearchDataTwo(res.data) })
    }


    const loadMoreSearchData = async () => {
        loadMoreSearch()
        
        setSliceCountOne(sliceCountOne + 6)
        setSliceCountTwo(sliceCountTwo + 6)
        
        setShowSearchData(true)
        setLoading(true);
        await axios.get(searchApi).then((res) => { setSearchDataTwo(res.data) })
        setLoading(false)
        // if (searchDataTwo.length === 0){
        //     console.log('empty')
        //     loadMoreSearchData()
        // } else {
            const showableData = searchDataTwo.filter(searchValue => searchValue.title.toLowerCase().includes(search.toLowerCase()))
            const setLimit = showableData.slice(sliceCountOne,sliceCountTwo).map((items, index) => { return items })
            setLoadMoreResultData(setLimit)
        // }
        // setLoadMoreResultData(setLimitTwo)
    }

    // const blogDetail = () => {
    //     console.log(document.getElementById('card-title').href)
    //     navigate('/blog/blog-details/')
    // }

    // console.log(document.location.search.substring(8))

    // console.log(document.location.pathname.substring(1))

    // console.log(cardData)
    // console.log(editorsPick)

    // const [url, setUrl] = useState('')

    // const conditionOne = document.location.pathname;
    // const conditionTwo = '/blog/'
    // const findBlog = conditionOne.filter(searchValue => searchValue.includes(conditionTwo))

    let {id} = useParams();


    return (
            <section className="py-4 container">
                <div>
                    {/* #main-header */}
                    <div id="et-main-area">
                        {/*Google Tag Manager (noscript) */}
                        {/* Google Tag Manager (noscript) */}
                        <noscript>&lt;iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KZK246Z"
                            height="0" width="0" style="display:none;visibility:hidden"&gt;&lt;/iframe&gt;</noscript>
                        {/* End Google Tag Manager (noscript) */}
                        <div id="content" className="bgs-site-content container">
                            <div className="bgs-module   bgs-module-layout-combo">
                                <h1 className="dynamic_title_h1 top_padding_50">Elite Site Optimizer Blog</h1>
                                <div className="module-title top_padding_30 bottom_margin_30">
                                    <h2 id='my-title' className="bottom_padding_15">{searchTitle === '' ? "Editor’s pick" : searchTitle}</h2>
                                </div>
                                <div align="right">
                                    <div className="blog_viewpageall col-lg-4 col-md-6">
                                        <div id="search-3" className="widget bgs-box blg_search bottom_padding_30" >
                                            {/* <form className="bgs-search-form"> */}
                                            {/* <input type="hidden" name="_token" defaultValue={token} /> */}
                                            {/* <input type="text" onChange={handleChange} placeholder="Type here to search..." /><button className="bgs-button-search"></button> */}
                                            {/* uncomment for Search field */}
                                            <input id='my-search'  type="text" onChange={handleChange} placeholder="Type here to search..." /><button id='click-me-again' onClick={submit} className="bgs-button-search"></button>
                                            {/* </form> */}
                                        </div>
                                    </div>
                                </div>
                                {showData ? <div className="row bgs-posts">
                                    <EditorsPick/>
                                </div> : ''}
                            </div>
                            {showData ? <div id="bgs-module-1" className="bgs-module module-type-posts  bgs-module-layout-combo">
                                <div className="module-header">
                                    <div className="module-title top_padding_30 bottom_margin_30">
                                        <h2 className="bottom_padding_15">Hottest Topics</h2></div>
                                </div>
                                <div className="row bgs-posts">
                                    <HottestTopics />
                                </div>
                            </div> : ''}
                            {showData ? <div id="bgs-module-2" className="bgs-module   bgs-module-layout-simple">
                                <div className="module-header">
                                    <div className="module-title top_padding_30 bottom_margin_30">
                                        <h2 className="bottom_padding_15">Latest articles</h2></div>
                                </div>
                                <div className="row bgs-posts  " id="setajaxblog">
                                    <div className="block" id={1} />
                                    <LatestTopics />
                                </div>
                                {/* <div id="bgs-module-2" className="bgs-module   bgs-module-layout-simple"> */}

                                {/* </div> */}
                            </div> : ''}


                            <div id="bgs-module-2" className="bgs-module   bgs-module-layout-simple">
                                {/* <div className="module-header">
                                <div className="module-title top_padding_30 bottom_margin_30">
                                    <h2 className="bottom_padding_15">Latest articles</h2></div>
                            </div> */}
                                {showSearchData  ? <div className="row bgs-posts  " id="setajaxblog">
                                    <div className="block" id={1} />
                                    {resultData.slice(sliceCountOne, sliceCountTwo).map((items, index) => {
                                        let rawdate = new Date(items.created_at)   
                
                                        let date = rawdate.getDate()
                                        let month = rawdate.toLocaleString('default', { month: 'long' })
                                        let year = rawdate.getFullYear()
                                        return (
                                            <div className="col-lg-4 col-md-6 col-sm-12 layout-simple">
                                                <article className="bgs-post bgs-post-a bgs-box" id={items.id}>
                                                    <div className="bnt-image">
                                                        <HashLink elementId='root' to={`/blogs/${items.url_slug}`} title={items.title}>
                                                            <img src={`https://strapi.elitesiteoptimizer.com/${items.featured_image.image_file.formats.small.url}`} alt={items.alt_text} />
                                                        </HashLink>
                                                    </div>
                                                    <div className="box-inner-p">
                                                        <div className="box-inner-ellipsis">
                                                            <div>
                                                                <h2 className="bnt-title h3"><HashLink elementId='page-container' to={`/blogs/${items.url_slug}`} state={{ from: `${items.url_slug}` }}>{items.title}</HashLink></h2>
                                                            </div>
                                                        </div>
                                                        <div className="bnt-meta">
                                                            <div className="meta-item meta-date"><span className="updated">{`${month} ${date}, ${year}`}</span>|
                                                        <span className="meta-item meta-views">{items.views === null ? 0 : items.views} Views</span>
                                                        |</div>
                                                        </div>
                                                        <div className="bnt-category">
                                                        {items.categories.map((subItem, subIndex) => { return <span><Link to={`/blogs/topics/${subItem.url_slug}`} state={{ from: `${subItem.url_slug}` }} style={{border: 'none'}} className="blog_categ" rel="category tag" >{subItem.name}</Link>&nbsp;&nbsp;</span> })}
                                                        </div>
                                                        <div className="bnt-content-cus">
                                                            <p className='short-description' style={{ fontSize: '14px' }}>{parse(items.page_content)}</p>
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                        )
                                    })}

                                </div> : ''}
                                
                            </div>



                            {showData ? <div className="row bgs-posts" id="setajaxblog">
                                {/* <div className='row'> */}
                                {cardData.map((items, index) => {
                                    let rawdate = new Date(items.created_at)   
                
                                    let date = rawdate.getDate()
                                    let month = rawdate.toLocaleString('default', { month: 'long' })
                                    let year = rawdate.getFullYear()
                                    return (
                                        <div className="col-lg-4 col-md-6 col-sm-12 layout-simple">
                                            <article className="bgs-post bgs-post-a bgs-box" id={items.id} width="400px">
                                                <div className="bnt-image">
                                                    <HashLink elementId='root' className="bnt-featured-image-url" to={`/blogs/${items.url_slug}`}>
                                                        <img src={`https://strapi.elitesiteoptimizer.com/${items.featured_image.image_file.formats.small.url}`} alt={items.alt_text} />
                                                    </HashLink>
                                                </div>
                                                <div className="box-inner-p">
                                                    <div>
                                                        <h2 style={{color: '#052A53'}} className="bnt-title h3"><Link to={`/blogs/${items.url_slug}`} state={{ from: `${items.url_slug}` }}>{items.title}</Link></h2>
                                                    </div>
                                                    <p className="bnt-meta">  
                                                        <div className="meta-item meta-date"><span className="published">{`${month} ${date}, ${year}`}</span>
                                                        {/* <span className="meta-item meta-views">{items.views === null ? 0 : items.views} Views</span> */}
                                                        </div>
                                                        {/* <a href="https://www.elitesiteoptimizer.com/blogs/digital-marketing/" className="blog_categ" rel="category tag">{items.categories.map((ito, indi) => {return ito.name, ito.name+','})}</a> */}
                                                        {/* <a href="" className="blog_categ" rel="category tag">{items.categories[0].name}</a> */}
                                                        <div className="bnt-category" style={{marginTop:'7px'}}>
                                                            {items.categories.map((subItem, subIndex) => { return <span><Link to={`/blogs/topics/${subItem.url_slug}`} state={{ from: `${subItem.url_slug}` }} className="blog_categ" rel="category tag" >{subItem.name}</Link>&nbsp;&nbsp;</span> })}
                                                        </div>
                                                        {/* <a href="https://www.elitesiteoptimizer.com/blogs/seo-tool/" className="blog_categ" rel="category tag">{items.categories[0].name}</a> */}

                                                        {/* <a href="https://www.elitesiteoptimizer.com/blogs/social-media/" className="blog_categ" rel="category tag">{items.categories[2].name = undefined ? '' : items.categories}</a> */}
                                                    </p>
                                                    <div className="bnt-content-cus">
                                                        <p className='short-description' style={{ fontSize: '14px' }}>{parse(items.page_content)}</p>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                    )
                                })}
                                {/* </div> */}
                            </div> : ''}

                            {showSearchData ? <div className="row bgs-posts" id="setajaxblog">
                                {/* <div className='row'> */}
                                {loadMoreResultData.map((items, index) => {
                                    let rawdate = new Date(items.created_at)   
                
                                    let date = rawdate.getDate()
                                    let month = rawdate.toLocaleString('default', { month: 'long' })
                                    let year = rawdate.getFullYear()
                                    return (
                                        <div className="col-lg-4 col-md-6 col-sm-12 layout-simple">
                                            <article className="bgs-post bgs-post-a bgs-box" id={items.id} width="400px">
                                                <div className="bnt-image">
                                                    <HashLink elementId='root' className="bnt-featured-image-url" to={`/blogs/${items.url_slug}`}>
                                                        <img src={`https://strapi.elitesiteoptimizer.com/${items.featured_image.image_file.formats.small.url}`} alt={items.alt_text} />
                                                    </HashLink>
                                                </div>
                                                <div className="box-inner-p">
                                                    <div>
                                                        <h2 className="bnt-title h3"><Link to={`/blogs/${items.url_slug}`} state={{ from: `${items.url_slug}` }}>{items.title}</Link></h2>
                                                    </div>
                                                    <p className="bnt-meta">  |
                                                        <div className="meta-item meta-date"><span className="published">{`${month} ${date}, ${year}`}</span>|
                                                        <span className="meta-item meta-views">{items.views === null ? 0 : items.views} Views</span>
                                                        |</div>
                                                        {/* <a href="https://www.elitesiteoptimizer.com/blogs/digital-marketing/" className="blog_categ" rel="category tag">{items.categories.map((ito, indi) => {return ito.name, ito.name+','})}</a> */}
                                                        {/* <a href="" className="blog_categ" rel="category tag">{items.categories[0].name}</a> */}
                                                        <div className="bnt-category">
                                                            {items.categories.map((subItem, subIndex) => { return <span><Link to={`/blogs/topics/${subItem.url_slug}`} state={{ from: `${subItem.url_slug}` }} className="blog_categ" rel="category tag" >{subItem.name}</Link>&nbsp;&nbsp;</span> })}
                                                        </div>
                                                        {/* <a href="https://www.elitesiteoptimizer.com/blogs/seo-tool/" className="blog_categ" rel="category tag">{items.categories[0].name}</a> */}

                                                        {/* <a href="https://www.elitesiteoptimizer.com/blogs/social-media/" className="blog_categ" rel="category tag">{items.categories[2].name = undefined ? '' : items.categories}</a> */}
                                                    </p>
                                                    <div className="bnt-content-cus">
                                                        <p className='short-description' style={{ fontSize: '14px' }}>{parse(items.page_content)}</p>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                    )
                                })}
                                {/* </div> */}
                            </div> : ''}
                            {/* <nav className="bgs-pagination bgs-load-more col-12 common_center top_padding_30 bottom_padding_30">
                        <a  id="getblogpagnination">Load More <i className="fa fa-spinner fa-spin" style={{ display: 'none' }} /></a>
                        <div className="bgs-loader">
                            <div className="double-bounce1" />
                            <div className="double-bounce2" />
                        </div>
                    </nav> */}
                        </div>
                    </div>
                </div>
                {showData ?
                    <nav className="bgs-pagination bgs-load-more col-12 common_center top_padding_30 bottom_padding_30" onClick={() => loadMore()}>
                        <a onClick={loadMore} disabled={loading} d="getblogpagnination" className='loadmore-btn'>
                            {loading && <span>Load More</span>}
                            {loading && (<i className="fa fa-spinner fa-spin" />)}
                            {!loading && <span>Load More</span>}
                            {/* {loading ? <i className="fa fa-spinner fa-spin" /> : 'Load More'} */}
                        </a>
                    </nav> : ''}

                {/* {resultData.length === 0 ? <h3>No Blogs Found</h3> : <div> */}
                {showSearchData && resultData.length != 0 && resultData.length >= 7 ?
                    <nav className="bgs-pagination bgs-load-more col-12 common_center top_padding_30 bottom_padding_30" onClick={() => loadMore()}>
                        <a onClick={loadMoreSearchData} disabled={loading} d="getblogpagnination" className='loadmore-btn'>
                            {loading && <span>Load More</span>}
                            {loading && (<i className="fa fa-spinner fa-spin" />)}
                            {!loading && <span>Load More</span>}
                        </a>
                        
                    </nav> : ''}
                {showSearchData && resultData.length === 0 ? <h3 style={{textAlign: 'center'}}>No Blogs Found</h3> : ''} 
                
                {/* </div>} */}
            

            </section>
    )
}

export default Blog;