import React, { useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import AstroFly from './../../../images/astro/astro-fly.png';
import './trial-report.css'
import { urlValidator } from './regex';
import ResultTable from '../ResultTable/ResultTable';
import axios from 'axios';

const TrialReport = () => {

    const [val, setVal] = useState('');
    const [err, setErr] = useState('');
    // const [click, setClick] = useState('');
    // const [show, setShow] = useState(localStorage.getItem('isValid'));
    const [show, setShow] = useState(true);
    const ApiUrl2 = `${process.env.REACT_APP_DOMAIN}/call-otp-api`
    const auth = {
        'username': 'devsite',
        'password': 'devsite'
    }
    const email2 = localStorage.getItem('userEmail')

    const user_data = {
        email: `${email2}`,
        site_url: `${val}`
    }

    const handleChange = (e) => {
        setVal(e.target.value);
        localStorage.setItem('userUrl', e.target.value)
    }

    const onSubmit = async (e) => {
        console.log(email2)
        console.log(val)
        e.preventDefault();
        setErr("");
        const validateUrl = urlValidator(val);

        if (validateUrl) {
            const response = await axios.post(ApiUrl2, user_data)
                .then((response) => {
                    if (response.data.result == 200) {
                        console.log(response.data.result)
                        setShow(true)
                        return window.location.reload(true);
                    } else {
                        console.log('exit')
                        return setShow(localStorage.setItem('isValid', false))
                    }
                })
                .catch(error => { console.log(error);})
        } else if (document.querySelector("#val").value === '') {
            console.log('2')
            setErr('This Field Should not be empty!');
        }
        else {
            console.log('3')
            setErr('Invalid URL')
        }
    };

    
    return (
        <div className="et-main-area">
            <div className="cta_bg_wrap_table">
                <div>
                    {/* <h3 style={{ fontSize: 40 }}>Target and Analyze your Website with<br /><span style={{ color: "#FFAE1F", fontSize: 75 }}>Elite Site Optimizer</span></h3>&nbsp; */}
                    <div className='container'>
                    <div className='row container_input'>
                    <div className='col-md-12 col-lg-9'>
                        <form className="trial-report-audit-url" action="action_page.php">
                            <input type="text" placeholder="Enter your URL here with http:// or https://" id="val" name="val" value={val} onChange={handleChange} />
                            <HashLink className="run-free-trial" to='#' type="submit" onClick={onSubmit}><b>Run Free Trial Audit</b></HashLink><br /><br />
                            <div className='Validate'>{err}</div>
                        </form>
                        
                        {/* <h5 className='note'>Note: A maximum of 3 domains will be allowed</h5>*/}
                        {/* <a href="https://www.elitesiteoptimizer.com/contact-us/?subject=free-trial-request">Get Your Free Trial</a><br/> */}
                    </div>
                    <div className='col-md-12 col-lg-3' >
                    <img id='astro-fly' src={AstroFly} />
                        <br />
                        </div>


                    </div>
                    </div>
                </div>
            </div>
  
            {/* Component to be insert to show report */}
            <br />
            {/* limited access */}
            {show ? < ResultTable /> : <h5 style={{ position: 'relative', color: 'red', marginLeft: '35%'}}>You have exceeded the maximum limit</h5>}
            {/* unlimited acceses to result: */}
            {/* <ResultTable /> */}
            {/* <div className='container_input_footer'>
                <h5>© 2020-2022 - eGrove Systems. All Rights Reserved.</h5>
            </div> */}
        </div>
    )
}

export default TrialReport;