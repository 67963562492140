import React, { useState, useEffect } from 'react';
import axios from 'axios';
import parse from 'html-react-parser';
import { HashLink } from 'react-router-hash-link';
import { Link, useParams } from "react-router-dom";
import './loaderstyles.css';

const LatestTopics = () => {
    const [token, setToken] = useState()
    const [countOne, setCountOne] = useState(0)
    const [countTwo, setCountTwo] = useState(6)
    const [latestTopics, setLattestTopics] = useState([])
    const [isLoading, setLoading] = useState(false)

    let { id } = useParams();

    const apiUrl = `/auth/local`;
    const callLatestTopics = `${process.env.REACT_APP_BLOGS_API}/blogs?publish_status=Publish&_sort=date_time_publish:DESC&_start=${countOne}&_limit=${countTwo}`

    const dataParams = {
        identifier: 'admin',
        password: 'admin@123!'
    }

    const getLatestTopicData = async (e) => {
        
        setLoading(true)
          const dataParams = {
            token: `${process.env.REACT_APP_STRAPI_AUTH_TOKEN}`,
            id: id
          }
          axios.post(`${process.env.REACT_APP_DOMAIN}/call-lattest-pick-blogs`, dataParams).then((res) => { setLattestTopics(res.data) })
          setLoading(false)
      }

    useEffect(() => {
        getLatestTopicData();
    }, [])

    const renderLatestTopic = (
        <div className="row bgs-posts">
            {latestTopics.slice(0, 6).map((items, index) => {
                let rawdate = new Date(items.created_at)   
                
                let date = rawdate.getDate()
                let month = rawdate.toLocaleString('default', { month: 'long' })
                let year = rawdate.getFullYear()
                return (
                    <div className="col-lg-4 col-md-6 col-sm-12 layout-simple">
                        <article className="bgs-post bgs-post-a bgs-box" id={items.id}>
                            <div className="bnt-image">
                                <HashLink elementId='root' to={`/blogs/${items.url_slug}`} title={items.title}>
                                    <img alt={items.alt_text} src={`https://strapi.elitesiteoptimizer.com/${items.featured_image.image_file.formats.small.url}`}  />
                                </HashLink>
                            </div>
                            <div className="box-inner-p">
                                <div className="box-inner-ellipsis">
                                    <div>
                                        <h2 className="bnt-title h3"><Link to={`/blogs/${items.url_slug}`} state={{ from: `${items.url_slug}` }}>{items.title}</Link></h2>
                                    </div>
                                </div>
                                <div className="bnt-meta">
                                    <div className="meta-item meta-date"><span className="updated">{`${month} ${date}, ${year}`}</span>
                                    {/* <span className="meta-item meta-views">{items.views === null ? 0 : items.views} Views</span>
                                    | */}
                                    </div>
                                </div>
                                <div className="bnt-category" style={{marginTop:'7px'}}>
                                    {items.categories.map((subItem, subIndex) => {
                                        return <span><Link to={`/blogs/topics/${subItem.url_slug}`} state={{ from: `${subItem.url_slug}` }} style={{ border: 'none' }} className="blog_categ" rel="category tag" >{subItem.name}</Link>&nbsp;&nbsp;</span>
                                    })}
                                </div>
                                <div className="bnt-content-cus">
                                <p className='short-description' style={{ fontSize: '14px' }}>{parse(items.page_content)}</p>
                                </div>
                            </div>
                        </article>
                    </div>
                )
            })}
        </div>
    )

    return (
        <div>
            {isLoading ? <i className="fa fa-spinner" /> : renderLatestTopic}
        </div>
    )
}

export default LatestTopics;