import React, { useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import about_us_banner from '../../images/str/uploads/about_us_banner.png';
import CEO from '../../images/str/uploads/CEO.png';
import digital_marketing1 from '../../images/str/uploads/digital_marketing1.png';
import custom_crawler from '../../images/str/uploads/custom_crawler.png';
import Chess_king from '../../images/eso/home_image/Chess_king.png';
import make_better from '../../images/eso/home_image/make_better.png';
import bg_4 from '../../images/eso/home_image/bg_4.png';
import bg_3 from '../../images/eso/home_image/bg_3.png';
import axios from 'axios';

const About = () => {
    useEffect(() => {
        document.title = 'About us - SEO Automation Tool | SEO Audit &amp; Reporting Tool';
    }, []);


    return (
        <div>
            {/* #main-header */}
            <div id="et-main-area">
                {/*Google Tag Manager (noscript) */}
                {/* Google Tag Manager (noscript) */}
                <noscript>&lt;iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KZK246Z"
                    height="0" width="0" style="display:none;visibility:hidden"&gt;&lt;/iframe&gt;</noscript>
                {/* End Google Tag Manager (noscript) */}
                {/* End Header */}
                {/* Banner */}
                {/* End Header */}
                {/* Page Title */}
                <div className="container-fluid pl-0 pr-0 page_title_outer_wrap">
                    <div className="container">
                        <div style={{background: 'cover'}} className="page_title_img_wrap">
                            <img src={about_us_banner} alt="about us banner" />
                        </div>
                        <h2>About Us</h2>
                        <h1>Services &amp; Tools For Website</h1>
                        <h3>Owners From Digital Marketers</h3>
                    </div>
                </div>
                {/* End Page Title */}
                {/* About us info */}
                <div className="container">
                    <div className="about_us_info_outer_wrap">
                        <h3>Booming Digital Marketing Business Wing With Elite Site Optimizer</h3>
                        <p>Seeing the inner workings of a successfully functioning machine always fascinates the engineer within everyone. <a href="https://www.egrovesys.com" target="_blank" rel="noopener">eGrove Systems</a> champions the advent of several technology solutions that accelerate the growth of the information and digital age. With its team of digital marketing leaders and subject matter experts, eGrove has developed a booming <a href="https://www.egrovesys.com/digital-marketing/" target="_blank" rel="noopener">digital marketing</a> business wing with Elite Site Optimizer.</p>
                        <p>Elite Site Optimizer advocates using the most advanced and powerful technologies such as Search Engine Optimization and <HashLink to="/services/custom-crawler" elementId='root'>Custom crawler development</HashLink>, which can shape the success of online businesses in this e-commerce age.</p>
                        <p>When assembling a website it takes a lot of conscious effort to be not sidetracked by design concerns and stay on-task with creating cohesive and captivating content compelling the user to stay with you and eventually convert. However, launching a well-crafted website is not the end of erecting a successful business online, only the beginning. We at Elite Site Optimizer, backed by the e-Commerce and <a href="https://www.egrovesys.com/mobile-application-development/" target="_blank" rel="noopener">mobile app development</a> domain experts from our parent organization eGrove Systems, understand this like no one else. Our product Elite Site Optimizer will become the eyes and ears of your <HashLink to="/features/webpage-seo-analysis/" elementId='root'>website’s performance</HashLink> so you can learn how to make it the best it can be.</p>
                        <br /><br />
                        <h3 style={{ width: '100%', textAlign: 'center' }}>Meet Our <strong style={{ fontWeight: 600 }}>CEO</strong></h3>
                        <div className="row justify-content-md-center">
                            <div style={{ maxWidth: '840px', width: '100%' }}>
                                <div className="row">
                                    <div className="col-lg-3 col-md-4">
                                    <p><img src={CEO} alt="CEO" style={{margin:'auto'}} /></p>
                                    </div>
                                    <div className="col-lg-9 col-md-8">
                                    <h5 style={{ fontSize: '22px', color: '#10274a', fontWeight: 600 }} className='text-xs-center'>Krish Govindaraj</h5>
                                        <h6 style={{ fontSize: '17px', color: '#10274a', fontWeight: 'normal' }} className='text-xs-center'>Chief Executive Officer</h6>
                                         <p>Krish Govindaraj holds a M.S in Project Management from the Florida Institute of Technology and a B.S in Computer Science from M.K. University. Krish has more than 20 years of IT experience in diverse fields such as professional services, resource management, offshore operations, digital marketing, project management &amp; product development.</p>
                                    </div>
                                    <p>Krish Govindaraj has worked for different companies including AT&amp;T, Time Inc., Bed Bath &amp; Beyond and Verizon Wireless. His impeccable expertise in Project Management and Digital Marketing will be an asset to make the Elite Site Optimizer brand navigate globally.<br />Krish Govindaraj is responsible for operations, building client relationships, networking and logistics management.</p>
                                </div>
                            </div>
                            <div className="clearfix">&nbsp;</div>
                        </div>
                    </div>
                </div>
                {/* End */}
                {/* About video section */}
                <div className="about_us_video_bg_wrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-5 col-lg-5 col-md-12">
                                <div className="about_us_video_left_col">
                                    <h3><span>Elite Site Optimizer</span><br /> In A Nutshell</h3>
                                    <div className="about_us_youtube_wrap">
                                        <iframe style={{ maxWidth: '100%' }} width="100%" height="100%" src="https://www.youtube.com/embed/5RiL3evFQEs" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                                    </div>
                                    <div className="about_youtube_footer_wrap">
                                        <p>A Complete Seo Tool To Improve Your Online Visibility</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-7 col-lg-7 col-md-12">
                                <div className="abouts_video_content_wrap">
                                    <h5>When we created our comprehensive and easy-to-use tool, we kept three different stakeholders in mind.</h5>
                                    <p>Firstly, we serve the digital marketing agencies who are trusted masterminds for many of their clients who in turn depend upon their reports to improve their online presence.</p>
                                    <p>Elite Site Optimizer will help such digital marketing agencies produce custom SEO reports that their customers will love and demonstrate the work they are putting in.</p>
                                    <p>Secondly, we are here to help the SEO professionals who are struggling to gauge and understand the performance of multiple websites of their respective organizations. Elite Site Optimizer can cut down their workload rapidly. Your SEO automation starts here.</p>
                                    <p>Finally, we can provide services for startups and small businesses who are actively trying to monitor their footprints and increase their ROI through their digital marketing efforts. Elite Site Optimizer can help these small businesses maximize their web presence.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End */}
                {/* Services */}
                <div className="container">
                    <div className="about_our_services_title_wrap">
                        <h3><span>Elite Site Optimizer’s</span> Value-Adding Services</h3>
                        <p>Given our strong combination of technology and marketing specialists, we offer customers two major specialized services – <HashLink to="/services/digital-marketing-services/" elementId='root'>Digital marketing services</HashLink> and custom crawler development.</p>
                    </div>
                    <div className="feature_listing_outer_wrap about_service_listing_outer_wrap">
                        {/* Service 1 */}
                        <div className="feature_listing_single_wrap">
                            <div className="feature_listing_img_outer_wrap">
                                <img src={digital_marketing1} alt="future list 1" />
                            </div>
                            <div className="feature_listing_content_wrap">
                                <h4>Digital Marketing Services:</h4>
                                <p>Our digital marketing services are designed for the fast-paced mobile age we live in.</p>
                                <p>Our three primary offerings are: Performance-based SEO services, mobile SEO services, and app store optimization. eGrove Systems’ eCommerce expertise gives us the ideal background to deliver these services to our clients.</p>
                            </div>
                        </div>
                        {/* End  1 */}
                        {/* Service 2 */}
                        <div className="feature_listing_single_wrap">
                            <div className="feature_listing_img_outer_wrap">
                                <img src={custom_crawler} alt="future list 2" />
                            </div>
                            <div className="feature_listing_content_wrap">
                                <h4>Custom Crawler Development:</h4>
                                <p>Our digital marketing services are designed for the fast-paced mobile age we live in.</p>
                                <p>Our three primary offerings are: Performance-based SEO services, mobile SEO services, and app store optimization. eGrove Systems’ eCommerce expertise gives us the ideal background to deliver these services to our clients.</p>
                            </div>
                        </div>
                        {/* End 2 */}
                    </div>
                </div>
                {/* End Services */}
                {/* Make us better */}
                <div className="make_us_better_outer_wrap">
                    <div className="container">
                        <div className="make_us_better_content_outer_wrap">
                            <div className="make_us_better_content_inner_wrap">
                                <h3>What Makes Us <span>Better?</span></h3>
                                <h6>We have embraced Business Intelligence and data visualization as an organization-wide magic wand that helped us not only see customer satisfaction but witness customer delight.</h6>
                                <p>ESO is no exception to this magic. We are happy to provide meaningful insights through BI reports and 360 degrees of data visualization that will help each of you to make informed decisions and confidently take steps to make your business succeed.</p>
                            </div>
                            <div className="make_us_better_image_outer_wrap">
                                <div className="make_us_better_image_inner_wrap">
                                    <div className="chess_image_outer_wrap">
                                        <img src={Chess_king} alt="chess_king" />
                                    </div>
                                    <img src={make_better} alt="make_us_better" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End */}
                {/* CTA */}
                <div className="cta_bg_wrap">
                    <div className="cta_bg_img_left_outer_wrap">
                        <img src={bg_4} alt="CTA_bg" />
                    </div>
                    <div className="cta_bg_img_right_outer_wrap">
                        <img src={bg_3} alt="CTA_bg" />
                    </div>
                    <div className="container">
                        <div className="cta_content_outer_wrap">
                            <h4><span>Let’s Get Started,</span><br />Contact The Experts <br />And Boost Your SEO Today!</h4>
                            <HashLink to="/contact-us/?subject=free-trial-request" elementId="contact_form_test" > Request Free Trial</HashLink>
                        </div>
                    </div>
                </div>
            </div>
            {/* #et-main-area */}
            {/* <div className="main-addon hide-overlay">
                <div className="overlay">
                    <div className="overlay-content">
                        <h2><button className="supportBTN" style={{ float: 'right' }}>x</button></h2>
                        <iframe title="support" src="https://www.elitesiteoptimizer.com/addon/kb/" />
                    </div>
                </div>
                <div className="buttons-container">
                    <button className="button supportBTN"><i className="fa fa-search" />Support</button>
                </div>
            </div> */}
             {/*Start of Zendesk Chat Script*/}
            {/*End of Zendesk Chat Script*/}{/*'}'*/}
            {/* <div class="cta_bg_wrap">
    <div class="cta_bg_img_left_outer_wrap"> <img src="https://www.elitesiteoptimizer.com/public/assets/eso/images/home_image/bg_4.png" alt="CTA_bg"> </div>
    <div class="cta_bg_img_right_outer_wrap"> <img src="https://www.elitesiteoptimizer.com/public/assets/eso/images/home_image/bg_3.png" alt="CTA_bg"> </div>
    <div class="container">
        <div class="cta_content_outer_wrap">
            <h4><span>Schedule A Meeting</span> To Take<br>Your Business To The Next Level.</h4> <a href="#">Get Your Free Trial</a> </div>
    </div>
</div> */}
            {/* #main-footer */}
            {/* #page-container */}
        </div>
    )
}

export default About;