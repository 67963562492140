import React, { useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import bg_5 from '../../../images/eso/home_image/bg_5.png';
import SEO_Dashboard from '../../../images/str/uploads/SEO_Dashboard.jpeg';
import On_page_seo_analysis from '../../../images/str/uploads/On_page_seo_analysis.jpeg';
import Link_analysis from '../../../images/str/uploads/Link_analysis.jpeg';
import Web_page_performance from '../../../images/str/uploads/Web_page_performance.jpeg';
import Analaytics_Console from '../../../images/str/uploads/Analaytics_Console.jpeg';
import Conent_audit from '../../../images/str/uploads/Conent_audit.jpeg';
import Schema_Validator from '../../../images/str/uploads/Schema_Validator.jpeg';
import Sitemap_Generator from '../../../images/str/uploads/Sitemap_Generator.jpeg';
import Rank_analysis1 from '../../../images/str/uploads/Rank_analysis1.jpeg';
import plagiarism_thumbnail from '../../../images/str/uploads/plagiarism_thumbnail.jpeg';
import bg_3 from '../../../images/eso/home_image/bg_3.png';
import bg_4 from '../../../images/eso/home_image/bg_4.png';

const Features = () => {
    useEffect(() => {
        document.title = 'Website Optimization Tool Features';
    }, []);
    return (
        <div>
            {/* #main-header */}
            <div id="et-main-area">
                {/*Google Tag Manager (noscript) */}
                {/* Google Tag Manager (noscript) */}
                <noscript>&lt;iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KZK246Z"
                    height="0" width="0" style="display:none;visibility:hidden"&gt;&lt;/iframe&gt;</noscript>
                {/* End Google Tag Manager (noscript) */}
                {/* End Header */}
                {/* Page Title */}
                <div className="container-fluid pl-0 pr-0 page_title_outer_wrap">
                    <div className="container">
                        <h2>Features</h2>
                        <h1>Elite Site Optimizer Features
                        </h1>
                        <h3 />
                    </div>
                </div>
                {/* End Page Title */}
                {/* feature Listing */}
                <div className="feature_bg_wrap">
                    <div className="container">
                        {/* Feature Info */}
                        <div className="row features_info_row_wrap">
                            <div className="col-xl-6 col-lg-6 col-md-7">
                                <div className="features_info_content_wrap">
                                    <h3> Explore and leverage the power of 60+ features to help audit and optimize your website</h3>
                                    <p><span style={{ fontWeight: 400 }}>Elite Site Optimizer is your new "Digital Marketing Swiss Army Knife" containing a variety of tools to help you audit and optimize your website. Choose the feature to find out how Elite Site Optimizer can help your Digital Marketing simple.</span></p>
                                    <p>&nbsp;</p>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-5">
                                <div className="feature_info_img_outer_wrap">
                                    <img src={bg_5} alt="feature_img" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Feature Listing */}
                <div className="container">
                    {/* Listing Features gird  */}
                    <div className="feature_listing_outer_wrap">
                        {/* Feature 1 */}
                        <div className="feature_listing_single_wrap">
                            <div className="feature_listing_img_outer_wrap">
                                <img src={SEO_Dashboard} alt="seo_dashboard" />
                            </div>
                            <div className="feature_listing_content_wrap">
                                <h4>SEO Dashboard</h4>
                                <p>ESO equips webmasters with SEO, analytics, performance, ranking KPI as visual widgets which are extremely simple, yet comprehensive.</p>
                                <HashLink to="/features/seo-dashboard" elementId='root'>
                                    Know More
                                </HashLink>
                            </div>
                        </div>
                        <div className="feature_listing_single_wrap">
                            <div className="feature_listing_img_outer_wrap">
                                <img src={On_page_seo_analysis} alt="On-Page-SEO-Analysis" />
                            </div>
                            <div className="feature_listing_content_wrap">
                                <h4>On-Page SEO Analysis</h4>
                                <p>Site explorer engine reviews all pages of the domain and generates the report for on-page optimization issues &amp; schema markup issues on the website.</p>
                                <HashLink to="/features/on-page-seo-analysis" elementId='root'>
                                    Know More
                                </HashLink>
                            </div>
                        </div>
                        <div className="feature_listing_single_wrap">
                            <div className="feature_listing_img_outer_wrap">
                                <img src={Link_analysis} alt="Link-Analysis" />
                            </div>
                            <div className="feature_listing_content_wrap">
                                <h4>Link Analysis</h4>
                                <p>The Link Analysis feature gives information about internal links, external links &amp; backlinks data and helps to optimize your website to its fullest potential.</p>
                                <HashLink to="/features/internal-link-checker" elementId='root'>
                                    Know More
                                </HashLink>
                            </div>
                        </div>
                        <div className="feature_listing_single_wrap">
                            <div className="feature_listing_img_outer_wrap">
                                <img src={Web_page_performance} alt="Webpage-Performance-Analysis" />
                            </div>
                            <div className="feature_listing_content_wrap">
                                <h4>Webpage Performance Analysis</h4>
                                <p>ESO’s Performance analysis feature keeps track of web page usability, UX, technical SEO issues for preferred URLs.</p>
                                <HashLink to="/features/webpage-seo-analysis/" elementId='root'>
                                    Know More
                                </HashLink>
                            </div>
                        </div>
                        <div className="feature_listing_single_wrap">
                            <div className="feature_listing_img_outer_wrap">
                                <img src={Analaytics_Console} alt="Analytics-Console" />
                            </div>
                            <div className="feature_listing_content_wrap">
                                <h4>Analytics Console</h4>
                                <p>Integrate with your Google Analytics account and get various important data including traffic, search queries &amp; top-performing pages.</p>
                                <HashLink to="/features/traffic-analytics" elementId='root'>
                                    Know More
                                </HashLink>
                            </div>
                        </div>
                        <div className="feature_listing_single_wrap">
                            <div className="feature_listing_img_outer_wrap">
                                <img src={Conent_audit} alt="Content-Audit" />
                            </div>
                            <div className="feature_listing_content_wrap">
                                <h4>Content Audit</h4>
                                <p>Save your website from ignominy by using ESO’s good Spell checker. Upload URLs, scan web pages, get recommendations and correct spelling.</p>
                                <HashLink to="/features/content-audit" elementId='root'>
                                    Know More
                                </HashLink>
                            </div>
                        </div>
                        <div className="feature_listing_single_wrap">
                            <div className="feature_listing_img_outer_wrap">
                                <img src={Schema_Validator} alt="Schema-Validator" />
                            </div>
                            <div className="feature_listing_content_wrap">
                                <h4>Schema Validator</h4>
                                <p>Scan, validate website’s rich-snippet schema markup to schema.org guidelines that supports JSON-LD, Microdata and RDFa formats and improve visibility.</p>
                                <HashLink to="/features/schema-validator" elementId='root'>
                                    Know More
                                </HashLink>
                            </div>
                        </div>
                        <div className="feature_listing_single_wrap">
                            <div className="feature_listing_img_outer_wrap">
                                <img src={Sitemap_Generator} alt="Sitemap-Generator" />
                            </div>
                            <div className="feature_listing_content_wrap">
                                <h4>Sitemap Generator</h4>
                                <p>Generate XML sitemap, HTML, image and video sitemap based on sitemaps.org protocol specifications and guide search engines to index web pages.</p>
                                <HashLink to="/features/sitemap-generator" elementId='root'>
                                    Know More
                                </HashLink>
                            </div>
                        </div>
                        <div className="feature_listing_single_wrap">
                            <div className="feature_listing_img_outer_wrap">
                                <img src={Rank_analysis1} alt="Rank-Analysis" />
                            </div>
                            <div className="feature_listing_content_wrap">
                                <h4>Rank Analysis</h4>
                                <p>Tracks the search rank position for the preferred keywords, web pages including mobile search rank &amp; competitor’s rank.</p>
                                <HashLink to="/features/keyword-ranking-checker" elementId='root'>
                                    Know More
                                </HashLink>
                            </div>
                        </div>
                        <div className="feature_listing_single_wrap">
                            <div className="feature_listing_img_outer_wrap">
                                <img src={plagiarism_thumbnail} alt="Plagiarism Checker" />
                            </div>
                            <div className="feature_listing_content_wrap">
                                <h4>Plagiarism Checker</h4>
                                <p>The most compelling web pages provide unique information for their target audience.</p>
                                <HashLink to="/features/plagiarism-checker" elementId='root'>
                                    Know More
                                </HashLink>
                            </div>
                        </div>
                        {/* End Feature 1 */}
                        {/* End Feature 9 */}
                    </div>
                    {/* End */}
                </div>
                {/* CTA */}
                {/* End CTA */}
                {/* Footer */}
                <div className="cta_bg_wrap">
                    <div className="cta_bg_img_left_outer_wrap">
                        <img src={bg_4} alt="CTA_bg" />
                    </div>
                    <div className="cta_bg_img_right_outer_wrap">
                        <img src={bg_3} alt="CTA_bg" />
                    </div>
                    <div className="container">
                        <div className="cta_content_outer_wrap">
                            <h4><span>Schedule A Meeting</span> To Take<br />Your Business To The Next Level.</h4>
                            <HashLink to="/contact-us" elementId="contact_form_test">Get your free trial</HashLink>
                        </div>
                    </div>
                </div>
            </div>
            {/* #et-main-area */}
            {/* <div className="main-addon hide-overlay">
                <div className="overlay">
                    <div className="overlay-content">
                        <h2><button className="supportBTN" style={{ float: 'right' }}>x</button></h2>
                        <iframe title="support" src="https://www.elitesiteoptimizer.com/addon/kb/" />
                    </div>
                </div>
                <div className="buttons-container">
                    <button className="button supportBTN"><i className="fa fa-search" />Support</button>
                </div>
            </div>  */}
            {/*Start of Zendesk Chat Script*/}
            {/*End of Zendesk Chat Script*/}{/*'}'*/}
            {/* <div class="cta_bg_wrap">
    <div class="cta_bg_img_left_outer_wrap"> <img src="https://www.elitesiteoptimizer.com/public/assets/eso/images/home_image/bg_4.png" alt="CTA_bg"> </div>
    <div class="cta_bg_img_right_outer_wrap"> <img src="https://www.elitesiteoptimizer.com/public/assets/eso/images/home_image/bg_3.png" alt="CTA_bg"> </div>
    <div class="container">
        <div class="cta_content_outer_wrap">
            <h4><span>Schedule A Meeting</span> To Take<br>Your Business To The Next Level.</h4> <a href="#">Get Your Free Trial</a> </div>
    </div>
</div> */}
            {/* #main-footer */}
            {/* #page-container */}
        </div>
    )
}

export default Features;