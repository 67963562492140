import React, { useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import alberto_161765dff2 from './../../../images/str/uploads/alberto_161765dff2.png';
import gothiclogo_54d4bf7a89 from './../../../images/str/uploads/gothiclogo_54d4bf7a89.png';
import candlsaddles_9409532d67 from './../../../images/str/uploads/candlsaddles_9409532d67.png';
import runnershop_9439ea6f4f from './../../../images/str/uploads/runnershop_9439ea6f4f.png';
import laura_8af43ee99a from './../../../images/str/uploads/laura_8af43ee99a.jpeg';
import contact_services_bc4e80e326 from './../../../images/str/uploads/contact_services_bc4e80e326.jpeg';
import mbdecor_6f6ba94668 from './../../../images/str/uploads/mbdecor_6f6ba94668.jpeg';
import bg_3 from './../../../images/eso/home_image/bg_3.png';
import bg_4 from './../../../images/eso/home_image/bg_4.png';
import solutions_banner from './../../../images/eso/home_image/solutions_banner.png';
import digital_marketing_f0f8376baa from './../../../images/str/uploads/digital_marketing_f0f8376baa.png';
import Union_2_59458031ad from './../../../images/str/uploads/Union_2_59458031ad.png';
import Union_1_591b1a3940 from './../../../images/str/uploads/Union_1_591b1a3940.png';
import Union_3_cc849f3e2e from './../../../images/str/uploads/Union_3_cc849f3e2e.png';
import DigitalMarketingCarousel from './../../../components/Carousel/DigitalMarketingCarousel';
import custom_crawler_79d53ca870 from './../../../images/str/uploads/custom_crawler_79d53ca870.png';
import web_maintain_9ff25f70b1 from './../../../images/str/uploads/web_maintain_9ff25f70b1.png';

const Services = () => {

  // Title Change
  useEffect(() => {
    document.title = 'Services | Elite Site Optimizer';
  }, []);

  return (
    <div>
      <div id="et-main-area">
        <div className="container-fluid pl-0 pr-0 page_title_outer_wrap">
          <div className="container">
            <h2>SERVICES</h2>
            <h1>Shape The Future Of Your Online Presence</h1>
          </div>
        </div>
        <div className="solution_info_bg_wrap">
          <div className="container">
            <div className="row features_info_row_wrap">
              <div className="col-xl-6 col-lg-6 col-md-7">
                <div className="features_info_content_wrap">
                  <h3>Elite Site Optimizer Services</h3>
                  <p>Elite Site Optimizer’s expert SEO team offers three major categories of service offerings
                    to our clients besides our BI-powered
                    <HashLink to="/" elementId='root'>SEO audit tool</HashLink>.
                    They are: Digital Marketing Services, Custom Crawler Development, and Website Maintenance.</p>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-5">
                <div className="feature_info_img_outer_wrap solutions_featured_image_outer_wrap">
                  <img src={solutions_banner} alt="feature_img" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* digital Marketing services */}
        <div className="container">
          <div className="row digital_marketing_row_wrap">
            <div className="col-xl-6 col-lg-6 col-md-12">
              <h3>Digital Marketing Services</h3>
              <p>With a wide array of marketing tools and tactics, it can be challenging to design marketing campaigns that fit your business’ goals.</p>
              <p>As your full-service digital marketing and productive agency, we collaborate with your business to build integrated marketing solutions
                that resonate with customers and perform powerful results.</p>
              <p>Our digital marketing services are designed to increase the reach of your marketing, sales, and distribution programs that target untapped
                markets and target specific online communities. We welcome clients who come to us looking for killer strategic campaigns.</p>
              <p>We classify our digital marketing services into three categories:</p>
            </div>
            <div className="col-xl-5 col-lg-6 col-md-12" style={{margin:'auto'}}>
              <div className="services_digital_marketing_img_wrap">
                <img src={digital_marketing_f0f8376baa} alt="digital_marketing" />
              </div>
            </div>
          </div>
          <div className="row service_digital_listing_outer_wrap">
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="solution_listing_single_content_wrap">
                <div className="solution_listing_icon_wrap">
                  <img src={Union_2_59458031ad} alt="Union_2" />
                </div>
                <h3>Performance Based SEO Services</h3>
                <p>Pay for those tailor-made SEO strategies bring success to your site</p>
                <p><HashLink to="/services/digital-marketing-services/performance-based-seo/" elementId='root'>Learn More</HashLink></p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="solution_listing_single_content_wrap">
                <div className="solution_listing_icon_wrap">
                  <img src={Union_1_591b1a3940} alt="Union_1" />
                </div>
                <h3>Mobile SEO Services</h3>
                <p>We help you exploit all the right ways to reach the top of the mobile search results</p>
                <p><HashLink to="/services/digital-marketing-services/mobile-seo" elementId='root'>Learn More</HashLink></p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="solution_listing_single_content_wrap">
                <div className="solution_listing_icon_wrap">
                  <img src={Union_3_cc849f3e2e} alt="app_store_optimization" />
                </div>
                <h3>App Store Optimization</h3>
                <p>SEO optimization elevates your app’s ranking in app stores from good to great</p>
                <p><HashLink to="/services/digital-marketing-services/app-store-optimization" elementId='root'>Learn More</HashLink></p>
              </div>
            </div>
          </div>
        </div>
        {/* end */}
        <div className="container-fluid pl-0 pr-0 service_maintance_bg_outer_wrap">
          <div className="container ">
            <div className="feature_listing_outer_wrap about_service_listing_outer_wrap">
              {/* Service 1 */}
              <div className="feature_listing_single_wrap">
                <div className="feature_listing_img_outer_wrap">
                  <img src={custom_crawler_79d53ca870} alt="service_img" />
                </div>
                <div className="feature_listing_content_wrap">
                  <h4>Custom Crawler Development</h4>
                  <p>We help you gather massive quantities of insightful data to analyze large amounts of webpages and their respective domains.</p>
                  <p>Extract data that is relevant for your organization and elevate your strategies to quickly reach your ROI.</p>
                  <HashLink to="/services/custom-crawler/" elementId='root'>Learn More</HashLink></div>
              </div>
              <div className="feature_listing_single_wrap">
                <div className="feature_listing_img_outer_wrap">
                  <img src={web_maintain_9ff25f70b1} alt="service_img" />
                </div>
                <div className="feature_listing_content_wrap">
                  <h4>Website Maintenance Services</h4>
                  <p>It is important to build a solid website and just as important to maintain it. Our professional website maintenance experts help
                    you manage complex website operations.</p>
                  <p>We offer a number of plans that make operating a website painless and convenient. Choose the right one for you</p>
                  <a href="https://products.egrovesys.com/website-maintenance-services/" target="_blank" rel="noopener">Learn More</a></div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
                <div className='row' style={{justifyContent:'center'}}>
                    <div className='col-md-8 col-10'>
                    <DigitalMarketingCarousel />
                    </div>
                    </div>
            </div>

        {/* client says */}

       {/*  <div className="container">
          <div className="our_client_says_outer_wrap">
            <h2>What Our Client</h2>
            <h3>Says About Us</h3>
            <div className="our_client_say_row_wrap">
              <div className="our_client_says_slider_outer_wrap">
                <div className="our_client_says_single_wrap">
                  <div className="client_image_outer_wrap">
                    <img src={alberto_161765dff2} alt="alberto" />
                  </div>
                  <div className="client_feedback_content_wrap">
                    <br/>
                    <br/>
                    <br/>
                    <h4>“Very professional team, work successfully with strong collaboration, they faced all the problems promptly addressing,
                      I hope to work with them in the next project.”</h4>
                    <h5>lberto. M</h5>
                    <br />
                    
                    <h6> </h6><h4 className="author-bio"><span className="item-company">VENTUNO</span>, <span className="author-location">S.R.L</span></h4>
                  </div>
                </div>
                
                <div className="our_client_says_single_wrap">
                  <div className="client_image_outer_wrap">
                    <img src={gothiclogo_54d4bf7a89} alt="gothiclogo" style={{marginTop: '-50px'}}/>
                  </div>
                  <div className="client_feedback_content_wrap">
                    <h4>“eGrove Systems did a great job adding onto our first project. I kept having different thoughts on how I needed my module to work
                      and they did great adapting. Communication was very quick and the developers were readily available. I have more projects down the line
                      and I will keep in touch when I am ready.”</h4>
                    <h5>Wes Stefanko</h5>
                    <br />
                    <h6> </h6><h4 className="author-bio"><span className="item-company">Gothic Jewelry</span></h4>
                  </div>
                </div>
                <div className="our_client_says_single_wrap">
                  <div className="client_image_outer_wrap">
                    <img src={candlsaddles_9409532d67} alt="candlsaddles" style={{marginTop: '-50px'}}/>
                  </div>
                  <div className="client_feedback_content_wrap">
                    <h4>“Thank you eGrove Systems for the great work on my website! Our team has been very pleased to work with you over the past couple of weeks.
                      This was my second project with eGrove Systems and I have nothing but good to say, I will be working with them in the future if the need arises.
                      Thank you guys!”</h4>
                    <h5>Josiah McCampbell</h5>
          
                    <br/>
                    <h6> </h6><h4 className="author-bio"><span className="item-company">Tech Fire Studio</span></h4>
                  </div>
                </div>
                <div className="our_client_says_single_wrap">
                  <div className="client_image_outer_wrap">
                    <img src={runnershop_9439ea6f4f} alt="runnershop" style={{marginTop: '-50px'}}/>
                  </div>
                  <div className="client_feedback_content_wrap">
                    <h4>“Very happy with the result of this website build – the costs increased throughout the project but we were able to explain and the team
                      helped us resolve everything without the charge in the end. Will definitely be working with them again!”</h4>
                    <h5>Erin</h5>
                    <br />
                    
                    <h6> </h6><h4 className="author-bio"><span className="item-company">The Runner Shop</span></h4>
                  </div>
                </div>
                <div className="our_client_says_single_wrap">
                  <div className="client_image_outer_wrap">
                    <img src={laura_8af43ee99a} alt="laura" style={{marginTop: '-50px'}}/>
                  </div>
                  <div className="client_feedback_content_wrap">
                    <h4>“I am very pleased with the results from eGrove Systems. They are very professional and answered my concerns on time. They are very knowledgeable
                      with the subject and need little instructions on how to perform the job. I will definitely hire them again in the future.”</h4>
                    <h5>Laura</h5>
                    <br />
                    
                    <h6> </h6><h4 className="author-bio"><span className="item-company">www.petiteperfectfit.com</span></h4>
                  </div>
                </div>
                <div className="our_client_says_single_wrap">
                  <div className="client_image_outer_wrap">
                    <img src={contact_services_bc4e80e326} alt="contact_services" style={{marginTop: '-50px'}}/>
                  </div>
                  <div className="client_feedback_content_wrap">
                    <h4>“Fantastic!! I have gone through this process several times with your team and my previous programmer in the past year or two. Your teams have been much
                      better than my last programmer and I’ve always been happy with the final project. I have to say, this particular project has been a very
                      smooth one, every step of the way!”</h4>
                    <h5>Scott Jenkins</h5>
                    <br />
                    
                    <h6> </h6><h4 className="author-bio"><span className="item-company">Owner, Contact Services, L.L.C</span></h4>
                  </div>
                </div>
                <div className="our_client_says_single_wrap">
                  <div className="client_image_outer_wrap">
                    <img src={mbdecor_6f6ba94668} alt="mbdecor" style={{marginTop: '-50px'}}/>
                  </div>
                  <div className="client_feedback_content_wrap">
                    <h4>“I would like to thank you (eGrove Systems) and your team for persisting with it and getting it over the line. Due to the complexity of design a room
                      it led to many delays. This is without a doubt, the longest project i have ever had on for a client – and i am really happy it is finally over!
                      So thanks for your work.”</h4>
                    <h5>Chris Rutherford</h5>
                    <h6> </h6><h4 className="author-bio"><span className="item-company">Metro Radio, UK</span></h4>
                  </div>
                </div>
              </div>
              <div className="client_feed_dots_wrap">
              </div>
            </div>
          </div>
        </div>*/}
        {/* CTA */}
        <div className="cta_bg_wrap">
          <div className="cta_bg_img_left_outer_wrap">
            <img src={bg_4} alt="CTA_bg" />
          </div>
          <div className="cta_bg_img_right_outer_wrap">
            <img src={bg_3} alt="CTA_bg" />
          </div>
          <div className="container">
            <div className="cta_content_outer_wrap">
              <h4><span>Schedule A Meeting</span> To Take<br />Your Business To The Next Level.</h4>
              <HashLink to="/contact-us" elementId="contact_form_test" >Get Your Free Trial</HashLink>
            </div>
          </div>
        </div>
      </div>

      {/* #et-main-area */}
      <div className="main-addon hide-overlay">
        <div className="overlay">
          <div className="overlay-content">
            <h2><button className="supportBTN" style={{ float: 'right' }}>x</button></h2>
            <iframe title="support" src="https://www.elitesiteoptimizer.com/addon/kb/" />
          </div>
        </div>
        <div className="buttons-container">
          <button className="button supportBTN"><i className="fa fa-search" />Support</button>
        </div>
      </div>
      {/*Start of Zendesk Chat Script*/}
      {/*End of Zendesk Chat Script*/}{/*'}'*/}
      {/* <div class="cta_bg_wrap">
          <div class="cta_bg_img_left_outer_wrap"> <img src="https://www.elitesiteoptimizer.com/public/assets/eso/images/home_image/bg_4.png" alt="CTA_bg"> </div>
          <div class="cta_bg_img_right_outer_wrap"> <img src="https://www.elitesiteoptimizer.com/public/assets/eso/images/home_image/bg_3.png" alt="CTA_bg"> </div>
          <div class="container">
        <div class="cta_content_outer_wrap">
        <h4><span>Schedule A Meeting</span> To Take<br>Your Business To The Next Level.</h4> <a href="#">Get Your Free Trial</a> </div>
     </div>
     </div> */}

      {/* <div id="cookie-notice" className="cn_bottom" style={{ color: 'rgb(255, 255, 255)', visibility: 'visible', backgroundColor: 'rgb(0, 0, 0)' }}>
        <div className="cookie_notice_container"> <span className="cn_notice_text">We use cookies to ensure that we give you the best experience on our website. 
        If you continue to use this site we will assume that you are happy with it.</span><a className="cn_set_cookie btn-primary btn-sm left_margin_15">I Agree</a>
        <a href="#" target="_blank" className="left_margin_15 btn-primary btn-sm cn_more_info">Privacy policy</a> </div>
      </div> */}
    </div >
  )
}

export default Services;