import React, { useEffect } from 'react';
//import { useNavigate } from 'react-router-dom';
import './Thankyou.css';
// import imageUrl from '../../images/eso/images/thankyou-banner.jpg';
import confirmIcon from '../../images/eso/images/confirm-icon.png';
import bg_3 from '../../images/eso/home_image/bg_3.png';
import bg_4 from '../../images/eso/home_image/bg_4.png';

const BannerContent = () => {
  useEffect(() => {
    document.title = 'Thankyou | Elite Site Optimizer';
  }, []);

  // const imageUrl = '../../images/eso/images/thankyou-banner.jpg'; 
   
  return (
    <div>
    
      {/* #main-header */}
      <div id="et-main-area">
        <div className="common_text_inner_banner confirm-msg_banner">
          <div className="container">
            <div className=".col-lg-12 banner-col ">
              <p>Ensure your online safety! Be cautious of fraudulent websites claiming to be us. Please note, we will NEVER request any cryptocurrency payments from you. </p>
              {/* <p>Elite Site Optimizer Team</p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BannerContent;