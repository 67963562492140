import React, { useState, useEffect } from 'react';
import axios from 'axios';
import parse from 'html-react-parser';
import { HashLink } from 'react-router-hash-link';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link, useParams } from "react-router-dom";
// import parseRange from 'http-range-parse';
import './blog.css';
import './hfstyle.css';
import EditorsPick from './staticTopics/EditorsPick';
import HottestTopics from './staticTopics/HottestTopics';
import LatestTopics from './staticTopics/LatestTopics';
// import 'bootstrap/dist/css/bootstrap.min.css';

const Topics = () => {

    let { id } = useParams();

    // const { idtwo } = useParams()
   

    const [countOne, setCountOne] = useState(0)
    const [countTwo, setCountTwo] = useState(6)
    const [token, setToken] = useState()
    const [cardData, setCardData] = useState([])
    const [moreData, setMoreData] = useState([])
    // const [searchTitle, setSearchTitle] = useState(null)
    // const [editorsPick, setEditorsPick] = useState([])
    // const [hottestPick, setHottestPick] = useState([])
    // const [lattestPick, setLatestPick] = useState([])
    const [search, setSearch] = useState('')
    const [showData, setShowData] = useState(true)
    const [topics, setTopics] = useState(null)

    const [searchData, setSearchData] = useState([])
    const [showSearchData, setShowSearchData] = useState(false)
    const [resultData, setResultData] = useState([])
    const [sliceCountOne, setSliceCountOne] = useState(0)
    const [sliceCountTwo, setSliceCountTwo] = useState(6)
    const [loading, setLoading] = useState(false)

    // const [detailUrl, setDetailUrl] = useState()
    const [loadingMore, setLoadingMore] = useState(false)
    const navigate = useNavigate();
    const location = useLocation();

    const urlState = location.state

    const apiUrl = `/auth/local`;
    // console.log(urlState)


    //strapi.egrovetech.com/blogs?publish_status=Publish&_sort=date_time_publish:DESC&categories.url_slug=web-design
    // const apiUrlForPage = `${process.env.REACT_APP_BLOGS_API}/blogs?publish_status=Publish&_sort=date_time_publish:DESC&categories.url_slug=${id}&_start=${countOne}&_limit=${countTwo}`
    const apiUrlForPage = `${process.env.REACT_APP_DOMAIN}/call-topics-api`
    const searchApi = `${process.env.REACT_APP_BLOGS_API}/blogs?publish_status=Publish&_sort=date_time_publish:DESC&_start=${0}&_limit=${200}`
    // const searchApiUnlimited =`/blogs?publish_status=Publish&_sort=date_time_publish:DESC&_start=${0}&_limit=${6}`
    // const apiUrlForPage = `/blogs?publish_status=Publish&url_slug=${}`
    // const api = `/blogs?publish_status=Publish&_sort=date_time_publish:DESC&_limit=4&url_slug_ne=why-seo-is-important-for-staffing-agencies`
    //https://strapi.egrovetech.com/blogs?publish_status=Publish&_sort=date_time_publish:DESC&_start=${0}&_limit=${200}

   
    const dataParams = {
        identifier: 'admin',
        password: 'admin@123!',
        countone: countOne,
        counttwo: countTwo,
        id: id
    }

    const callTopicsApi = async () => {
        setLoading(true)
        await axios.post(apiUrlForPage, dataParams).then((response) => { setCardData(response.data) })
            .catch((error) => { console.log(error) })
        setLoading(false)
    }

    console.log(cardData)

    const loadMore = async () => {
        setLoadingMore(true);
        setCountOne(countOne + 6)
        setCountTwo(countTwo + 6)

        await axios.post(apiUrlForPage, dataParams).then((response) => { setMoreData(response.data) })
            .catch((error) => { console.log(error) })
        setLoadingMore(false)
    }

    console.log('card data----> ', cardData)

    const handleChange = (e) => {
        setSearch(e.target.value);
        // callSearchApi()
    }

    const callSearchApi = async () => {
        await axios.get(searchApi).then((res) => { setSearchData(res.data) })
    }

    useEffect(() => {
        // document.title = 'SEO Audit &amp; Reporting Tool | SEO Automation Tool | Official Blog';
        window.scrollTo(0, 0)
        const title = id.replace(/[^a-zA-Z]/g, ' ')
        setTopics(title)
        callTopicsApi()
        callSearchApi()
    }, [id])

    console.log(search)
    const submit = () => {
        // console.log(search)
        // setSearchTitle(search)

        if (search === '') {
            setShowData(true)
            setShowSearchData(false)
            console.log('submit if case')
        } else {
            setShowData(false)
            setShowSearchData(true)
            callSearchApi()
            const showableData = searchData.filter(searchValue => searchValue.title.toLowerCase().includes(search))
            const setLimit = showableData.map((items, index) => { return items })
            setResultData(setLimit)
            console.log('submit else case')
        }

        // await axios.get(searchApi).then((res) => { setSearchData(res.data)})
        if (searchData.length === 0) {
            console.log('empty')
            submit()
        } else {
            // console.log('searchdata', searchData)
            console.log('exit')
        }
        // const showableData = searchData.filter(searchValue => searchValue.title.toLowerCase().includes(search))
        // const setLimit = showableData.slice(0, 6).map((items, index) => { return items })
        // console.log(showableData)
        // setResultData(searchData.map((items, index) => { return items }))
        // console.log(searchData)
    }








    // const [searchDataTwo, setSearchDataTwo] = useState([])
    // const [loadMoreResultData, setLoadMoreResultData] = useState([])

    // const loadMoreSearch = async () => {
    //     await axios.get(searchApi).then((res) => { setSearchDataTwo(res.data) })
    // }


    // const loadMoreSearchData = async () => {
    //     loadMoreSearch()

    //     setSliceCountOne(sliceCountOne + 6)
    //     setSliceCountTwo(sliceCountTwo + 6)

    //     setShowSearchData(true)
    //     setLoading(true);
    //     await axios.get(searchApi).then((res) => { setSearchDataTwo(res.data) })
    //     setLoading(false)
        // if (searchDataTwo.length === 0){
        //     console.log('empty')
        //     loadMoreSearchData()
        // } else {
        // const showableData = searchDataTwo.filter(searchValue => searchValue.title.toLowerCase().includes(search))
        // const setLimit = showableData.slice(sliceCountOne, sliceCountTwo).map((items, index) => { return items })
        // setLoadMoreResultData(setLimit)
        // }
        // setLoadMoreResultData(setLimitTwo)
    // }

    // const blogDetail = () => {
    //     console.log(document.getElementById('card-title').href)
    //     navigate('/blog/blog-details/')
    // }

    // console.log(document.location.search.substring(8))

    // console.log(document.location.pathname.substring(1))

    // console.log(cardData)
    // console.log(editorsPick)

    // const [url, setUrl] = useState('')

    // const conditionOne = document.location.pathname;
    // const conditionTwo = '/blog/'
    // const findBlog = conditionOne.filter(searchValue => searchValue.includes(conditionTwo))

    


    return (
        <div> 
            {/* #main-header */}
            <div id="et-main-area">
                <div id="content" className="bgs-site-content container">
                    <div className="bgs-module   bgs-module-layout-combo">
                        <h1 className="dynamic_title_h1 top_padding_50">Elite Site Optimizer Blog</h1>
                        <div className="module-title top_padding_30 bottom_margin_30">
                            <h2 id='my-title' className="bottom_padding_15">{topics}</h2>
                        </div>
                        <div className="row clearfix">
                            <div className="blog_viewpageall pull-right col-lg-4 col-md-6">
                                <div id="search-3" className="widget bgs-box blg_search bottom_padding_30">
                                    {/* <form className="bgs-search-form"> */}
                                    {/* <input type="hidden" name="_token" defaultValue={token} /> */}
                                    {/* <input type="text" onChange={handleChange} placeholder="Type here to search..." /><button className="bgs-button-search"></button> */}
                                    {/* uncomment for Search field */}
                                    {/* <input id='my-search' type="text" onChange={handleChange} placeholder="Type here to search..." /><button id='click-me-again' onClick={submit} className="bgs-button-search"></button> */}
                                    {/* </form> */}
                                </div>
                            </div>
                        </div>
                        
                        {loading ? <i style={{alignItems: 'center',justifyContent: 'center',display: 'flex',fontSize: '35px',marginBottom: '40vw', marginTop: '10vw'}} className="fa fa-spinner" /> : showData ? <div className="row bgs-posts">
                            {/* <EditorsPick /> */}
                            <div className='row bgs-posts'>
                                {cardData.map((items, index) => {
                                    let rawdate = new Date(items.created_at)   
                
                                    let date = rawdate.getDate()
                                    let month = rawdate.toLocaleString('default', { month: 'long' })
                                    let year = rawdate.getFullYear()
                                    return (
                                        <div className="col-lg-4 col-md-6 col-sm-12 layout-simple">
                                            <article id={items.id} width="400px">
                                                <HashLink elementId='root' className="bnt-featured-image-url" to={`/blogs/${items.url_slug}`}>
                                                    <img src={`https://strapi.elitesiteoptimizer.com/${items.featured_image.image_file.formats.small.url}`} alt={items.alt_text} />
                                                </HashLink>
                                                <div className="desc_content">
                                                    <h2 id='card-title' className="bnt-title h3"><Link to={`/blogs/${items.url_slug}`} state={{ from: `${items.url_slug}` }}>{items.title}</Link></h2>

                                                    <p className="post-meta">  
                                                        <span className="published">{`${month} ${date}, ${year}`}</span> 
                                                        {/* <span className="meta-item meta-views">{items.views === null ? 0 : items.views} Views</span>
                                                        | */}
                                                        <div className="bnt-category" style={{marginTop:'7px'}}>
                                                        
                                                            {items.categories.map((subItem, subIndex) => { return <span><Link to={`/blogs/topics/${subItem.url_slug}`} state={{ from: `${subItem.url_slug}` }} style={{ border: 'none' }} className="blog_categ" rel="category tag" >{subItem.name}</Link> &nbsp;&nbsp;</span> })}
                                                        </div>
                                                    </p>
                                                    <div className="bnt-content-cus">
                                                        <p className='short-description' style={{ fontSize: '14px' }}>{parse(items.page_content)}</p>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                    )
                                })
                                }
                                {moreData.map((items, index) => {
                                    let rawdate = new Date(items.created_at)   
                
                                    let date = rawdate.getDate()
                                    let month = rawdate.toLocaleString('default', { month: 'long' })
                                    let year = rawdate.getFullYear()
                                    return (
                                        <div className="col-lg-4 col-md-6 col-sm-12 layout-simple">
                                            <article id={items.id} width="400px">
                                                <HashLink elementId='root' className="bnt-featured-image-url" to={`/blogs/${items.url_slug}`}>
                                                    <img src={`https://strapi.elitesiteoptimizer.com/${items.featured_image.image_file.formats.small.url}`} alt={items.alt_text} />
                                                </HashLink>
                                                <div className="desc_content">
                                                    <h2 id='card-title' className="bnt-title h3"><Link to={`/blogs/${items.url_slug}`} state={{ from: `${items.url_slug}` }}>{items.title}</Link></h2>

                                                    <p className="post-meta">  |
                                                        <span className="published">{`${month} ${date}, ${year}`}</span> |
                                                        <span className="meta-item meta-views">{items.views === null ? 0 : items.views} Views</span>
                                                        |
                                                        <div className="bnt-category">
                                                            {items.categories.map((subItem, subIndex) => { return <span><Link to={`/blogs/topics/${subItem.url_slug}`} state={{ from: `${subItem.url_slug}` }} style={{ border: 'none' }} className="blog_categ" rel="category tag" >{subItem.name}</Link> &nbsp;&nbsp;</span> })}
                                                        </div>
                                                    </p>
                                                    <div className="bnt-content-cus">
                                                        <p className='short-description' style={{ fontSize: '14px' }}>{parse(items.page_content)}</p>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                    )
                                })
                                }
                            </div>
                        </div> : ''}
                        
                    </div>
                </div>
                {showData && cardData.length != 0 ?
                    <nav className="bgs-pagination bgs-load-more col-12 common_center top_padding_30 bottom_padding_30" onClick={() => loadMore()}>
                        <a onClick={loadMore} disabled={loading} d="getblogpagnination" className='loadmore-btn'>
                            {loadingMore && <span>Load More</span>}
                            {loadingMore && (<i className="fa fa-spinner fa-spin" />)}
                            {!loadingMore && <span>Load More</span>}
                            {/* {loading ? <i className="fa fa-spinner fa-spin" /> : 'Load More'} */}
                        </a>
                    </nav> : ''}
            </div>
        </div >
    )
}

export default Topics;