import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import parse from 'html-react-parser';
import BlogForm from './BlogForm';
import { HashLink } from 'react-router-hash-link';
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import './dynamicblogs.css';
// import './loaderstyles.css';

const DynamicBlogs = () => {

  const callBlogAgain = () => {
    DynamicBlogs()
  }

  let { id } = useParams();
  let { idtwo } = useParams();

  // const apiUrl = `/auth/local`;
  const apiUrlForPage = `${process.env.REACT_APP_BLOGS_API}/blogs?publish_status=Publish&url_slug=${id}`
  const apiUrlForRecentPosts = `${process.env.REACT_APP_BLOGS_API}/blogs?publish_status=Publish&_sort=date_time_publish:DESC&_start=${0}&_limit=${4}`
  const apiUrlForTopics = `${process.env.REACT_APP_BLOGS_API}/categories`
  //const callEditorsPick = `/blogs?publish_status=Publish&editors_pick=true&_sort=date_time_publish:DESC&_start=${countOne}&_limit=${countTwo}`
  // const checkApi = `/general-data`

  const [pageData, setPageData] = useState([])
  const [recentPosts, setRecentPosts] = useState([])
  const [topics, setTopics] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingTopics, setLoadingTopics] = useState(false)

  const dataParams = {
    identifier: 'admin',
    password: 'admin@123!'
  }

 

  const getPageData = async () => {
    setLoading(true)
    await axios.get(apiUrlForPage).then((response) => { setPageData(response.data) })
      .catch((error) => { console.log(error) })
    setLoading(false)
  }

  const recentPostsData = async (e) => {
    setLoading(true)
    await axios.get(apiUrlForRecentPosts).then((response) => { setRecentPosts(response.data) })
      .catch((error) => { console.log(error) })
    setLoading(false)
  }

  const getTopicsData = async (e) => {
    setLoadingTopics(true)
    await axios.get(apiUrlForTopics).then((response) => { setTopics(response.data) })
      .catch((error) => { console.log(error) })
    setLoadingTopics(false)
  }

  const callStrapiApi = async () => {
    
    const dataParams = {
      token: `${process.env.REACT_APP_STRAPI_AUTH_TOKEN}`,
      id: id
    }
    await axios.post(`${process.env.REACT_APP_DOMAIN}/call-blog-page-data`, dataParams).then((res) => { setPageData(res.data) })
    await axios.post(`${process.env.REACT_APP_DOMAIN}/call-recent-posts-data`, dataParams).then((res) => { setRecentPosts(res.data) })
    await axios.post(`${process.env.REACT_APP_DOMAIN}/call-blog-topics-data`, dataParams).then((res) => { setTopics(res.data) })
 
}


  useEffect(() => {
    callStrapiApi()
    window.scrollTo(0, 0)
  }, [id])

  console.log(pageData)
  return (
    <div className="dynamicblog-loader">

      {loading ? <i style={{alignItems: 'center',justifyContent: 'center',display: 'flex',fontSize: '35px',marginBottom: '50vw', marginTop: '20vw'}} className="fa fa-spinner" /> : pageData.map((items, index) => {
        let rawdate = new Date(items.created_at)
        console.log(items);
        let date = rawdate.getDate()
        let month = rawdate.toLocaleString('default', { month: 'long' })
        let year = rawdate.getFullYear()
        return (
          <div>
            {/* #main-header */}
            <div id="et-main-area">
              {/*Google Tag Manager (noscript) */}
              {/* Google Tag Manager (noscript) */}
              <noscript>&lt;iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KZK246Z"
                height="0" width="0" style="display:none;visibility:hidden"&gt;&lt;/iframe&gt;</noscript>
              {/* End Google Tag Manager (noscript) */}
              <style dangerouslySetInnerHTML={{ __html: "\n    .ohnohoney{\n        opacity: 0;\n        position: absolute;\n        top: 0;\n        left: 0;\n        height: 0;\n        width: 0;\n        z-index: -1;\n    }\n" }} />
              {/* <link rel="stylesheet" href="https://staging.elitesiteoptimizer.com/public/assets/eso/css/jssocials.css" type="text/css" media="all" />
          <link rel="stylesheet" href="https://staging.elitesiteoptimizer.com/public/assets/eso/css/jssocials-theme-classic.css" type="text/css" media="all" /> */}


              <div id="items.id" className="blog_viewpageall bgs-site-content container top_margin_50">
                <div className="row">

                  <div className="bgs-content bgs-single-layout-2 col-md-8 col-12">
                    <article className="bgs-box post">
                      <div className="box-inner-p-bigger box-single">
                        <div className="text-center">
                          <div className="bnt-header common_left">
                            <h1 className="bnt-title">{items.title}</h1>
                            <div className="bnt-meta">
                              <div className="meta-item meta-date"><span className="updated">{`${month} ${date}, ${year}`}| </span></div>
                              <div className="meta-item meta-comments"><a href="#respond">Add
                                comment</a> &nbsp;|</div>
                              {/* <div className="meta-item">{items.views === null ? 0 : items.views} Views &nbsp;|</div> */}
                              <div className="bnt-category  bottom_padding_20">
                                {items.categories.map((subItem, subIndex) => { return <span><Link to={`/blogs/topics/${subItem.url_slug}`} state={{ from: `${subItem.url_slug}` }} className="blog_categ" rel="category tag" >{subItem.name}</Link>&nbsp;&nbsp;</span> })}
                              </div>
                              <div className="bnt-tags meta-item">
                                <span>Tags:</span>
                                {items.categories.map((subItem, subIndex) => { return <span><Link to={`/blogs/topics/${subItem.url_slug}`} state={{ from: `${subItem.url_slug}` }} className="blog_categ" rel="category tag" >{subItem.name}</Link>&nbsp;&nbsp;</span> })}
                              </div>
                            </div>
                          </div>
                          <div className="bnt-image bottom_margin_15 top_margin_15">
                            <img src={`https://strapi.elitesiteoptimizer.com${items.featured_image.image_file.url}`} alt="`${items.featured_image.alt_text}`" srcSet={`https://strapi.elitesiteoptimizer.com/${items.featured_image.image_file.url} 480w, https://strapi.elitesiteoptimizer.com/${items.featured_image.image_file.url} 768w, https://strapi.elitesiteoptimizer.com/${items.featured_image.image_file.url} 1000w`} />
                          </div>
                        </div>
                        <Helmet>
                          <title>{items.title}</title>
                          <meta name='description' content={items.page_metadata.meta_description} />
                        </Helmet>
                        <div className="bnt-headline h5">
                        </div>
                        <div id="share" />
                        <div className="bnt-content">
                          <div className="ptag">
                            <p style={{ fontSize: '14px' }}>{parse(items.page_content)}</p>
                          </div>
                        </div>
                        <div id="respond" />{/*top scrolling*/}
                        <nav className="bgs-prev-next-nav row top_margin_15 bottom_margin_30 left_padding_15 right_padding_15">
                          {/* <div id="nxt-prev-blog-loader" className="common_center" style={{ width: '100%' }}> */}
                          {/* <i className="fa fa-spinner fa-spin fa-2x" /></div> */}
                          <div className="bgs-prev-link col-md-6 col-6">
                            <a id="prev-blog" style={{ display: 'none' }} href="#">
                              <span className="bgs-pn-ico"><i className="fa fa fa-chevron-left" /></span>
                              <span className="bgs-pn-link" id="prev-blog-name">Previous</span>
                            </a>
                          </div>
                          <div className="bgs-next-link col-md-6 col-6">
                            <a id="next-blog" style={{ display: 'none' }} href="#">
                              <span className="bgs-pn-ico"><i className="fa fa fa-chevron-right" /></span>
                              <span className="bgs-pn-link" id="next-blog-name">Next</span>
                            </a>
                          </div>
                        </nav>
                      </div>
                    </article>
                    <div className="bgs-ad"><a href="https://www.elitemcommerce.com/" target="_blank">
                      <img src="https://www.egrovesys.com/blog/wp-content/uploads/sites/2/2019/08/emc_single_post.jpeg" alt="Mobile App Builder for your eCommerce Store in Android and iOS" className="aligncenter size-full wp-image-8154" width={728} />
                    </a></div>
                    <div id="blogs-commands-data" className="bgs-load-more top_margin_20">
                      <div id="blogs-commands-data-loader" className="common_center">
                        <i className="fa fa-spinner fa-spin" style={{ display: 'none' }} /></div>
                    </div>
                    <button className="btn btn-warning btn-sm text-white cmd-more-btn" style={{ display: 'none' }} onclick="getCommandsData()">More</button>
                    <div id="comments" className="bgs-comments bgs-box bottom_margin_50">
                      <div className="module-header">
                        {/* <div className="module-title bottom_margin_20 top_margin_30">
                          <h4 className="h2">Add comment</h4>
                        </div> */}
                        <BlogForm />
                      </div>
                      {/* <div className="comment-respond">
                        <h3 id="reply-title" className="comment-reply-title"> <small /></h3>
                        <form action="https://staging.elitesiteoptimizer.com/blog_commend/" id="command_form" method="post" className="comment-form" noValidate>
                          <input type="hidden" name="_token" defaultValue="grWEvfKvbchjaptOyY55BvZEFNjXhrKK6VAEBFTs" />                        <div className="comment-form-comment bottom_margin_20">
                            <textarea autoComplete="nope" id="comment" name="comment" cols={45} rows={8} aria-required="true" placeholder="Comment" defaultValue={""} />
                          </div>
                          <div className="comment-form-author bottom_margin_20">
                            <input id="author" name="author" type="text" defaultValue size={30} maxLength={245} placeholder="Name*" required="required" />
                          </div>
                          <div className="comment-form-email bottom_margin_20">
                            <input id="email" name="email" type="email" defaultValue size={30} maxLength={100} placeholder="Email*" required="required" />
                          </div>
                          <div className="comment-form-url bottom_margin_20">
                            <input id="url" name="url" type="url" defaultValue size={30} maxLength={200} placeholder="Website" />
                          </div>
                          <div className="comment-form-cookies-consent bottom_margin_20">
                            <label htmlFor="cookies-consent"> <input id="cookies-consent" name="cookies-consent" type="checkbox" required defaultValue="yes" style={{ width: '15px' }} />I consent to have this website store my submitted information so they can respond to my inquiry(<a href="https://staging.elitesiteoptimizer.com/terms-conditions/" target="_blank">Terms &amp; Conditions</a> and  <a href="https://staging.elitesiteoptimizer.com/privacy-policy/" target="_blank">Privacy Policy</a>)</label>
                            <div id="fm_privacy_policy6-error" />
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6">
                            <center style={{ marginTop: '5px' }}>
                              {/* <div id="recaptcha" class="g-recaptcha" data-sitekey="6LflX64cAAAAAAiR4Iy9PWSv4kc-j_LrJGEzuRK4"></div> 
                              <div id="recaptcha" className="g-recaptcha" data-sitekey="6LdfUK4cAAAAADox2w6ImlAz4NNigRLrArkwIQ57" />
                              <span className="err-hide" id="err-recaptcha" />
                              <label id="captacha-error" style={{ display: 'none', color: 'red' }}>This field is required.</label>
                            </center>
                          </div>
                          <div className="contact_full bottom_padding_15">
                            <input type="hidden" id="g-recaptcha-response" name="g-recaptcha-response" />
                            <div className="form-submit bottom_margin_20">
                              <input type="hidden" name="comment_post_ID" defaultValue={432} id="comment_post_ID" />
                              <input type="hidden" name="comment_post_Title" defaultValue="The Power of combining email marketing and SEO for your business" id="comment_post_Title" />
                              <input type="hidden" name="blog_url" defaultValue="the-power-of-combining-email-marketing-and-seo-for-your-business" id="comment_post_Title" />
                              <input type="hidden" name="comment_parent" id="comment_parent" defaultValue={0} />
                              <input name="submit" type="submit" id="submit" className="submit" defaultValue="Submit Comment" style={{ marginTop: '12px' }} />
                            </div>
                            <input className="ohnohoney" autoComplete="off" type="text" id="first_name" name="first_name" placeholder="Your name here" defaultValue />
                            <input className="ohnohoney" autoComplete="off" type="text" id="cmp_site_url" name="cmp_site_url" placeholder="Your company url here" defaultValue />
                          </div>
                        </form>
                      </div> */}
                      
                      <div className="bgs-related">
                        {/* <div className="bgs-module" id="blogs-similar">
                          <div id="blogs-similar-loader" className="common_center">
                            <i className="fa fa-spinner fa-spin fa-2x" />
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="bgs-sidebar col-md-4 col-12 left_padding_50">
                    <div id="search-3" className="widget bgs-box blg_search  bottom_padding_30">
                      {/* <form className="bgs-search-form" action="https://staging.elitesiteoptimizer.com/search/" method="post"> */}
                      {/* <input type="hidden" name="_token" defaultValue="grWEvfKvbchjaptOyY55BvZEFNjXhrKK6VAEBFTs" /> */}
                      {/* <input name="s" type="text"  id="search_blogs" placeholder="Type here to search..." /> */}
                      {/* <button type="submit" className="bgs-button-search">Search</button> */}
                      {/* </form> */}
                    </div>
                    <div id="bgs_category_widget-2" className="widget bgs-box bgs_category_widget bottom_padding_30 clearfix">
                      <h4 className="widget-title">Topics</h4>
                      {/* <div id="blog-category-count-loader" className="common_center blog-category-count-loader-class">
                        <i className="fa fa-spinner fa-spin fa-2x" />
                        </div> */}
                      <div>
                        {loadingTopics ? <i className="fa fa-spinner" /> : topics.map((topic, index) => (
                          <div>
                            <ul className="bgs-count-color left_padding_0" id="blog-category-count">
                              <li><Link to={`/blogs/topics/${topic.url_slug}`} state={{ from: `${topic.url_slug}` }} class="bgs-cat-col-43">
                                <span className="category-text">{topic.name}</span>
                                <span className="bgs-count bgs-cat bgs-cat-43">{topic.blogs.length}</span></Link></li>
                            </ul>
                          </div>
                        )
                        )}
                      </div>
                    </div>
                    <div id="bgs_posts_widget-2" className="widget bgs-box bgs_posts_widget bottom_padding_30 clearfix">
                      <h4 className="widget-title bottom_padding_20">Recent Posts</h4>
                      <div className="bgs-posts-widget ">
                        {/* <div id="recent-blogs-data-loader" className="common_center blog-category-count-loader-class">
                          <i className="fa fa-spinner fa-spin fa-2x" />
                        </div> */}

                        <div>
                          {recentPosts.map((posts, index) => (
                            <div style={{ display: "flex", marginBottom: "10px" }}>
                              <img src={`https://strapi.elitesiteoptimizer.com${posts.featured_image.image_file.url}`} alt={posts.alt_text} height="70px" width="100px" /> <Link onClick={callBlogAgain} state={{ id: `${posts.url_slug}` }} to={`/blog/${posts.url_slug}`} style={{ marginLeft: "14px", color: 'black' }}>{posts.title}</Link>
                            </div>
                          )
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="bgs-sticky-sidebar" style={{}}>
                      <div id="mks_ads_widget-2" className="widget bgs-box mks_ads_widget">
                        <ul className="mks_adswidget_ul large left_padding_0">
                          <li data-showind={0} style={{ display: 'list-item' }}>
                            <a href="https://www.elitemcommerce.com/" target="_blank">
                              <img src="https://www.egrovesys.com/wp-content/uploads/sites/2/2019/08/emc_side_content.jpeg" alt="emc_side_content.jpeg" style={{ width: '300px', height: '250px' }} width={300} height={250} />
                            </a>
                          </li>
                          <li data-showind={1} style={{ display: 'none' }}>
                            <a href="https://www.elitesiteoptimizer.com/" target="_blank">
                              <img src="https://www.egrovesys.com/blog/wp-content/uploads/sites/2/2019/06/300x250-Your-online-success.jpg" alt="300x250-Your-online-success.jpg" style={{ width: '300px', height: '250px' }} width={300} height={250} />
                            </a>
                          </li>
                          <li data-showind={2} style={{ display: 'none' }}>
                            <a href="https://www.modulebazaar.com/" target="_blank">
                              <img src="https://www.egrovesys.com/blog/wp-content/uploads/sites/2/2019/06/300-250_v2.jpg" alt="300-250_v2.jpg" style={{ width: '300px', height: '250px' }} width={300} height={250} />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* #et-main-area */}
            <div className="main-addon hide-overlay">
              <div className="overlay">
                <div className="overlay-content">
                  <h2><button className="supportBTN" style={{ float: 'right' }}>x</button></h2>
                  <iframe title="support" src="https://staging.elitesiteoptimizer.com/addon/kb/" />
                </div>
              </div>
              <div className="buttons-container">
                <button className="button supportBTN"><i className="fa fa-search" />Support</button>
              </div>
            </div> {/*Start of Zendesk Chat Script*/}
            {/*End of Zendesk Chat Script*/}{/*'}'*/}
            {/* <div class="cta_bg_wrap">
    <div class="cta_bg_img_left_outer_wrap"> < src="https://staging.elitesiteoptimizer.com/public/assets/eso/images/home_image/bg_4.png" alt="CTA_bg"> </div>
    <div class="cta_bg_img_right_outer_wrap"> <img src="https://staging.elitesiteoptimizer.com/public/assets/eso/images/home_image/bg_3.png" alt="CTA_bg"> </div>
    <div class="container">
        <div class="cta_content_outer_wrap">
            <h4><span>Schedule A Meeting</span> To Take<br>Your Business To The Next Level.</h4> <a href="#">Get Your Free Trial</a> </div>
    </div>
</div> */}


            {/* #page-container */}
          </div>
        )
      })}

    </div>
  )
}

export default DynamicBlogs;
