import React, { useState, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import { useLocation } from 'react-router-dom';

const FeaturesOptions = () => {
    
    const location = useLocation();
    const [url, setUrl] = useState(null);
    
    useEffect(() => {
        setUrl(location.pathname);
        console.log(url)
        console.log("pathname===============", location.pathname)
    }, [location]);

    return (
        <div>
            <div className="seo_side_menu_outer_wrap">
                <HashLink to="/features/seo-dashboard/" elementId="root" className={"underline" + (url === "/features/seo-dashboard/" ? " active" : "")}>SEO Dashboard</HashLink>
                <HashLink to="/features/webpage-seo-analysis/" elementId="root" className={"underline" + (url === "/features/webpage-seo-analysis/" ? " active" : "")}>Performance Analysis</HashLink>
                <HashLink to="/features/keyword-ranking-checker/" elementId="root" className={"underline" + (url === "/features/keyword-ranking-checker/" ? " active" : "")}>Rank Analysis</HashLink>
                <HashLink to="/features/on-page-seo-analysis/" elementId="root" className={"underline" + (url === "/features/on-page-seo-analysis/" ? " active" : "")}>On-Page SEO Analysis</HashLink>
                <HashLink to="/features/internal-link-checker/" elementId="root" className={"underline" + (url === "/features/internal-link-checker/" ? " active" : "")}>Link Checker</HashLink>
                <HashLink to="/features/schema-validator/" elementId="root" className={"underline" + (url === "/features/schema-validator/" ? " active" : "")}>Schema Validator</HashLink>
                <HashLink to="/features/traffic-analytics/" elementId="root" className={"underline" + (url === "/features/traffic-analytics/" ? " active" : "")}>Analytics Console</HashLink>
                <HashLink to="/features/sitemap-generator/" elementId="root" className={"underline" + (url === "/features/sitemap-generator/" ? " active" : "")}>Sitemap Generator</HashLink>
                <HashLink to="/features/content-audit/" elementId="root" className={"underline" + (url === "/features/content-audit/" ? " active" : "")}>Content Audit</HashLink>
                <HashLink to="/features/plagiarism-checker/" elementId="root" className={"underline" + (url === "/features/plagiarism-checker/" ? " active" : "")}>Plagiarism Checker</HashLink>
            </div>
        </div>
    )
}

export default FeaturesOptions;