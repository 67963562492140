import React, { useState } from "react";
import Slider from "react-slick";

const DigitalMarketingCarousel = () => {
  const [settings] = useState({
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  });

 
  const testimonials = [
    {
      name: "Chris Rutherford",
      company: "Metro Radio, UK",
      feedback:
        "I would like to thank you (eGrove Systems) and your team for persisting with it and getting it over the line. Due to the complexity of design a room it led to many delays. This is without a doubt, the longest project i have ever had on for a client – and i am really happy it is finally over! So thanks for your work.",
      image: "https://strapi.elitesiteoptimizer.com/uploads/mbdecor_6f6ba94668.jpeg",
    },
    {
      name: "Alberto. M",
      company: "VENTUNO S.R.L",
      feedback:
        "Very professional team, work successfully with strong collaboration, they faced all the problems promptly addressing, I hope to work with them in the next project.",
      image: "https://strapi.elitesiteoptimizer.com/uploads/alberto_161765dff2.png",
    },
    {
      name: "Wes Stefanko",
      company: "Gothic Jewelry",
      feedback:
        "eGrove Systems did a great job adding onto our first project. I kept having different thoughts on how I needed my module to work and they did great adapting. Communication was very quick and the developers were readily available. I have more projects down the line and I will keep in touch when I am ready.",
      image: "https://strapi.elitesiteoptimizer.com/uploads/gothiclogo_54d4bf7a89.png",
    },
    {
      name: "Josiah McCampbell",
      company: "Tech Fire Studio",
      feedback:
        "Thank you eGrove Systems for the great work on my website! Our team has been very pleased to work with you over the past couple of weeks. This was my second project with eGrove Systems and I have nothing but good to say, I will be working with them in the future if the need arises. Thank you guys!",
      image: "https://strapi.elitesiteoptimizer.com/uploads/candlsaddles_9409532d67.png",
    },
    {
      name: "Erin",
      company: "Runner Shop",
      feedback:
        "Very happy with the result of this website build – the costs increased throughout the project but we were able to explain and the team helped us resolve everything without the charge in the end. Will definitely be working with them again!",
      image: "https://strapi.elitesiteoptimizer.com/uploads/runnershop_9439ea6f4f.png",
    },
    {
      name: "Scott Jenkins",
      company: "Owner, Contact Services, L.L.C",
      feedback:
        "Fantastic!! I have gone through this process several times with your team and my previous programmer in the past year or two. Your teams have been much better than my last programmer and I’ve always been happy with the final project. I have to say, this particular project has been a very smooth one, every step of the way!",
      image: "https://strapi.elitesiteoptimizer.com/uploads/contact_services_bc4e80e326.jpeg",
    },
    {
      name: "Laura",
      company: "www.petiteperfectfit.com",
      feedback:
        "I am very pleased with the results from eGrove Systems. They are very professional and answered my concerns on time. They are very knowledgeable with the subject and need little instructions on how to perform the job. I will definitely hire them again in the future.",
      image: "https://strapi.elitesiteoptimizer.com/uploads/laura_8af43ee99a.jpeg",
    },
  ];

  return (
    <div className="our_client_says_outer_wrap our_client_says_service_wrap" style={{/*width:"70%",marginLeft:175*/}} >
      <h2>What Our Client</h2>
      <h3>Says About Us</h3>
      <div className="our_client_say_row_wrap">
        <Slider {...settings}>
          {testimonials.map((testimonial) => (
            <div key={testimonial.name}>
              <div className="our_client_says_single_wrap">
                <div className="client_image_outer_wrap">
                  <img src={testimonial.image} alt={testimonial.name} />
                </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div className="client_says_content_wrap">
                  <p>{testimonial.feedback}</p>
                  <h4>{testimonial.name}</h4>
                  <span>{testimonial.company}</span>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default DigitalMarketingCarousel;