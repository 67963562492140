import React, { useState, useEffect, useRef } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { HashLink } from 'react-router-hash-link';
import './Contactstyles.css';
import contact_us_bg from './../../images/eso/home_image/contact_banner_two.png'
import bg_3 from '../../images/eso/home_image/bg_3.png';
import bg_4 from '../../images/eso/home_image/bg_4.png';
import $ from 'jquery';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
  
const ContactUs = () => {

  useEffect(() => {
    document.title = 'Contact Us | SEO Automation Tool';
  }, []);

  const [isActive, setActive] = useState(false);

  const onClick = () => {
    setActive(!isActive)
  };

  const ContactURL = `${process.env.REACT_APP_DOMAIN}/call-contact-form-api`;

  const navigate = useNavigate();

  //------------------------------------------------------------------------------
  const [value, setValue] = useState("")
  const [isVerified, setIsVerified] = useState(false)
  const [captcha, setCaptcha] = useState(false)
  const [checkBox, setCheckBox] = useState(false)
  const freeTrialSelect = "?subject=free-trial-request"
  

  const urlValue = document.location.search
  
  const [select, setSelect] = useState(false)

  const [loading, setLoading] = useState(false)
  const Ref = useRef(null);

  const selectHandler = (event) => {
    setSelect(event.target.value)
  }

  const callCheckFunction = () => {
    setCheckBox(!checkBox)
  }

  useEffect(() => {
    if (urlValue === freeTrialSelect) {
      setSelect("Free Trial Request")
    } else if(urlValue === "?subject=basic-yearly-plan-request"){
      setSelect("Basic Yearly plan")
    } else if(urlValue === "?subject=pro-yearly-plan-request"){
      setSelect("Pro Yearly plan")
    } else if(urlValue === "?subject=basic-monthly-plan-request"){
      setSelect("Basic Monthly plan")
    } else if(urlValue === "?subject=pro-monthly-plan-request"){
      setSelect("Pro Monthly plan")
    }
    else {
      setSelect("Others")
    }
  }, []);

  const [inputValues, setInputValue] = useState({
    name: "",
    email: "",
    phoneNo: "",
    others: "",
    websiteUrl: "",
    message: "",
    checkbox: false,
    recaptcha: false
  });

  const { name, email, phoneNo, message, websiteUrl, competitorUrl } = inputValues

  const [validation, setValidation] = useState({
    name: "",
    email: "",
    phoneNo: "",
    others: "",
    websiteUrl: "",
    message: "",
    checkbox: "",
    recaptcha: ""
  });

  const checkCaptcha = () => {
    setCaptcha(true)
  }

  const checkValidation = () => {
    let errors = { ...validation };

    //Name validation
    if ($('#name').val() === "" ) {
      errors.name = "This field is required.";
    } else if (!(/^[a-zA-Z]+$/g.test($('#name').val()))) {
      errors.name = "Please enter alphabet character only";
    } else {
      errors.name = "";
    }

    //Email validation
    if ($('#email').val() === "" ) {
      errors.email = "This field is required.";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test($('#email').val())) {
      errors.email = "Please enter valid Email address";
    } else {
      errors.email = "";
    }

    //Phone validation
    if ($('#phoneNo').val() === "" ) {
      errors.phoneNo = "This field is required.";
    }
    else if($('#phoneNo').val().length < 10 ||$('#phoneNo').val().length >= 15) {
      errors.phoneNo = "Please enter valid phone number";
    } 
    else {
      errors.phoneNo = "";
    }

    //Website URL Validation 
    if ($('#websiteUrl').val() === "") {
      errors.websiteUrl = "Please enter a valid URL starting with http:// or https://";
    } else if (!/^(https?|s?ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test($('#websiteUrl').val())) {
      errors.websiteUrl = "Please enter valid URL";
    } else {
      errors.websiteUrl = "";
    }

    //Message validation
    if ($('#message').val() === "") {
      errors.message = "This field is required.";
    } else {
      errors.message = "";
    }

    //checkbox validation
    // if (inputValues.checkbox == false) {
    //   errors.checkbox = "This field is required.";
    // } else {
    //   errors.checkbox = "";
    // }

    //recaptcha validation
    // if (inputValues.recaptcha == false) {
    //   errors.recaptcha = "This field is required.";
    // } else {
    //   errors.recaptcha = "";
    // }

    if (checkBox) {
      errors.checkbox = "";
    } else {
      errors.checkbox = "This field is required.";
    }

    // captcha.reset();
    //recaptcha validation
    if (captcha) {
      errors.recaptcha = "";
    } else {
      errors.recaptcha = "This field is required.";
    }

    return setValidation(errors);
  }

  // const ref = useRef(null);

  const onChange = (value) => {
    console.log("Captcha value:", value);
    setIsVerified({ isVerified: true })
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputValue({ ...inputValues, [name]: value });
    // checkValidation();
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
     Ref.current.focus();
    checkValidation();
    // console.log("input-----", inputValues);
    if (name != '' && email != '' && phoneNo != '' && message != '' && checkBox && captcha) {
      setLoading(true)
      await axios.post(ContactURL, {
        email: inputValues.email,
        url: inputValues.websiteUrl,
        name: inputValues.name,
        phoneNo: value + " " + inputValues.phoneNo,
        // subject: "test",
        message: inputValues.message,
        competitor_url: inputValues.competitorUrl
      })
        .then((response) => {
          console.log("Get.Data=====>", response)
          //redirect logic
          if (response.data.result == 200) {
            navigate("/thankyou")
          }
        })
        .catch(error => { console.log(error) })
      setLoading(false)
    } else {
      console.log("Please fill all the fields")
    }
  }

    //------------------------------------------------------------------------------

    return (
      <div>
        {/* #main-header */}
        <div id="et-main-area">
          {/*Google Tag Manager (noscript) */}
          {/* Google Tag Manager (noscript) */}
          <noscript>&lt;iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KZK246Z"
            height="0" width="0" style="display:none;visibility:hidden"&gt;&lt;/iframe&gt;</noscript>
          {/* End Google Tag Manager (noscript) */}
          {/* End Header */}
          {/* Page Title */}
          <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n        .contact_form.seo_country_code_wrap {\n    position: relative;\n    height: 36px;\n    width: 100%;\n    background:transparent;\n    border: none;\n    margin-bottom: 3px;\n    border-bottom: 1px solid rgb(0 0 0 / 31%) !important;\n    top: -7px;\n}\n#captacha-error{\n    color: red!important;\n}\n\n.contact_form.seo_country_code_wrap span.select2.select2-container.select2-container--default {\n     position: absolute;\n    top: 5px;\n    background: transparent;\n    z-index: 1;\n    left: 0px;\n}\n\n.contact_form.seo_country_code_wrap span.select2.select2-container.select2-container--default span.select2-selection.select2-selection--single {\n    background: transparent;\n    border: none !important;\n        padding: 0;\n}\n\n.contact_form.seo_country_code_wrap input#phone_id {\n       position: absolute;\n    top: 0px !important;\n    color: #fff;\n    background: transparent;\n    left: 50px !important;\n    padding: 0 !important;\n    color: #444;\n    border-bottom: none !important;\n\n}\n\n.contact_form.seo_country_code_wrap input#phone {\n    width: 70% !important;\n    padding-left: 0px;\n    position: absolute;\n    right: 0;\n    background: transparent;\n    z-index: 1;\n    border-bottom: none !important;\n}\n\n\n.contact_form.seo_country_code_wrap .form-group.cl_contact.cl_phone {\n    margin-bottom: 0;\n}\n  \n  .contact_form.seo_country_code_wrap .form-group.cl_contact.cl_phone {\n    width: 100%;\n}\n\n.contact_form.seo_country_code_wrap .form-group .select2-selection__rendered span.flag:before {  { display: none; } }\n\n#phone-error{\n  margin-top: 43px;\n}\n\n  .ohnohoney{\n        opacity: 0;\n        position: absolute;\n        top: 0;\n        left: 0;\n        height: 0;\n        width: 0;\n        z-index: -1;\n    }\nhtml {\n  scroll-behavior: smooth;\n}\n\n\n      " }} />
          <link rel="stylesheet" href="https://www.elitesiteoptimizer.com/public/assets/plugin/Country-Flags-Responsive-CSS-Sprite-master/css/flags.css" />
          <div className="container-fluid pl-0 pr-0 page_title_outer_wrap">
            <div className="container">
              <div className="page_title_img_wrap">
                <img src={contact_us_bg} alt="contact_us_bg" />
              </div>
              <h2>Contact Us</h2>
              <h1>Get In Touch With Our</h1>
              <h3>Search Engine Experts Today</h3>
            </div>
          </div>
          {/* End Page Title */}
          <div className="container-fluid pl-0 pr-0 contact_address_banner_outer_wrap">
            <div className="container">
              <div className="contact_us_map_outer usa_map_outer_wrap" style={isActive ? { display: 'none' } : { display: 'block' }} id="us">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3037.356285521101!2d-74.39113940000001!3d40.423109!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3cf7055a9a93b%3A0xce3a73e576c08d5!2s6%20Auer%20Ct%2C%20East%20Brunswick%2C%20NJ%2008816%2C%20USA!5e0!3m2!1sen!2sin!4v1677145484390!5m2!1sen!2sin" width="100%" height="100%" style={{ border: 0 }} allowFullScreen loading="lazy"></iframe>
                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1518.6548658770048!2d-74.391257!3d40.42414!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3cf704bb74875%3A0x8c2544f1c84c62f6!2s2g%20Auer%20Ct%2C%20East%20Brunswick%2C%20NJ%2008816%2C%20USA!5e0!3m2!1sen!2sin!4v1632226042128!5m2!1sen!2sin" width="100%" height="100%" style={{ border: 0 }} allowFullScreen loading="lazy" /> */}
              </div>
              <div className="contact_us_map_outer india_map_outer_wrap" style={isActive ? { display: 'block' } : { display: 'none' }} id="india">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7772.07995358463!2d80.200151!3d13.096653!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc8a3ffe93c0f246a!2sEgrove%20Systems%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1632232924926!5m2!1sen!2sin" width="100%" height="100%" style={{ border: 0 }} allowFullScreen loading="lazy" />
              </div>
              <div className="row">
                <div className="col-xl-7 col-lg-6 col-md-5">
                </div>
                <div className="col-xl-5 col-lg-6 col-md-7">
                  <div className="contact_address_tab_outer_wrap">
                    <div className="tabbable-panel custom_tabs_outer_wrap">
                      <div className="tabbable-line">
                        <ul className="nav nav-tabs ">
                          <li>
                            <a href="#us_address_tabs" onClick={() => { document.getElementById('us').style.display = 'block'; document.getElementById('india').style.display = 'none' }} className="nav-link active tabvalue " value={1} data-index={1} data-toggle="tab">
                              <h5>United States</h5>
                            </a>
                          </li>
                          <li>
                            <a href="#india_address_tabs" onClick={() => { document.getElementById('india').style.display = 'block'; document.getElementById('us').style.display = 'none' }} className="nav-link tabvalue" value={2} data-index={2} data-toggle="tab">
                              <h5>India</h5>
                            </a>
                          </li>
                        </ul>
                        <div className="tab-content">
                          {/* Tab 1 */}
                          <div className="tab-pane active" id="us_address_tabs">
                            <div style={{ color: "white" }}><br />
                              <h5><i class="fa fa-map-marker" aria-hidden="true" style={{ color: "#FFAE1F", fontSize: 25 }}></i>&nbsp;&nbsp;&nbsp;6G Auer Ct.  East Brunswick<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;NJ 08816</h5><br />
                              {/* <h5>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ph: <a href="callto:+1 732-307-2655" style={{ color: "white" }}>+1 732-307-2655</a> <br />
                                <a href="mailto:sales@elitesiteoptimizer.com" style={{ color: "white" }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;sales@elitesiteoptimizer.com</a></h5> */}
                            </div>
                            <p
  style={{
    fontSize: 16,
    textAlign: "justify",
    color: "white",
    padding: "6px 14px 0px"
  }}
>
  <span style={{ color: "#ffae1f", fontWeight: 600 }}>Note: </span>To report a scam, please email us at <a href="mailto:report-scam@elitesiteoptimizer.com" style={{ color: "#FFAE1F" }}>report-scam@elitesiteoptimizer.com</a>
<br/>Additionally, we highly recommend you report any such activity/incident to your local law enforcement immediately. 
</p>

                          </div>
                          {/* End Tab 1 */}
                          {/* Tab 2 */}
                          <div className="tab-pane" id="india_address_tabs">
                            <div style={{ color: "white" }}><br />
                              <h5><i class="fa fa-map-marker" aria-hidden="true" style={{ color: "#FFAE1F", fontSize: 25 }}></i>&nbsp;&nbsp;&nbsp;#1354, 11th St, I Block,<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Anna nagar, Chennai 600040</h5><br />
                              {/* <h5>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ph: <a href="callto:+91 44 4553 6638" style={{ color: "white" }}>+91 44 4553 6638</a> <br />
                                <a href="mailto:sales@elitesiteoptimizer.com" style={{ color: "white" }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;sales@elitesiteoptimizer.com</a></h5> */}
                            </div>
                            <p
  style={{
    fontSize: 16,
    textAlign: "justify",
    color: "white",
    padding: "6px 14px 0px"
  }}
>
  <span style={{ color: "#ffae1f", fontWeight: 600 }}>Note: </span>To report a scam, please email us at <a href="mailto:report-scam@elitesiteoptimizer.com" style={{ color: "#FFAE1F" }}>report-scam@elitesiteoptimizer.com</a>
<br/>Additionally, we highly recommend you report any such activity/incident to your local law enforcement immediately. 
</p>

                          </div>
                          {/* End Tab 2 */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Contact from */}
          <div className="container" id="contact_form_test">
            <div className="contact_title_wrap">
              <h2>Get In Touch With <br /><span>Our Website Optimization Experts Today</span></h2>
            </div>
            <form className="contact_form_outer_wrap" id="contact_form" onSubmit={handleSubmit} autoComplete="off">
              <input type="hidden" name="_token" defaultValue="76cxPcH6SkGPN0WvfVloDHkxTVdYfWbmTj3907hE" />
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="form-group">
                    <input type="text" className="form-control cl_contact clyour_name" id="name" placeholder="Your Name *" name="name"
                      onChange={(e) => handleChange(e)}
                      value={name} ref={Ref} />
                    <span className='text-error'>{validation.name}</span>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="form-group">
                    <input type="email" className="form-control cl_contact cl_email" id="email" placeholder="Your Email *" name="email"
                      onChange={(e) => handleChange(e)}
                      value={email} />
                    <span className='text-error'>{validation.email}</span>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="form-group">
                    <PhoneInput
                      international
                      defaultCountry="US"
                      value={value}
                      onChange={setValue}
                      className="icon-flag"
                    />
                    <input type="number" name="phoneNo" id="phoneNo" placeholder="Phone Number *" className='phone-no-field' step="0.01" value={phoneNo} onChange={(e) => handleChange(e)} />
                    <span className='text-error'>{validation.phoneNo}</span>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 ">
                  <div className="form-group others-field">
                    <select className="form-control select_comp" required name="select" id="select" onChange={(e) => selectHandler(e)} value={select}>
                      <option value="Others" selected="&quot;selected&quot;">Select Reason</option>
                      <option value="Report Scam">Report Scam</option>
                      <option value="Subscription Request">Subscription Request</option>
                      <option value="Free Trial Request">Free Trial Request</option>
                      <option value=" Free Audit Request"> Free Audit Request</option>
                      <option value="Basic Yearly plan"> Basic Yearly Plan Request</option>
                      <option value="Basic Monthly plan"> Basic Monthly Plan Request</option>
                      <option value="Pro Yearly plan"> Pro Yearly Plan Request</option>
                      <option value="Pro Monthly plan"> Pro Monthly Plan Request</option>
                    </select>
                  </div>
                </div>

                <div className="col-xl-12 col-lg-12 col-md-12">
                  <div className="form-group">
                    <input type="text" name="websiteUrl" className="form-control cl_contact cl_subject" id="websiteUrl" placeholder="Your website URL*" size={50}
                      value={websiteUrl} onChange={(e) => handleChange(e)} />
                    <span className='text-error'>{validation.websiteUrl}</span>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <div className="form-group">
                    {select === "Free Trial Request" ? <input type="text" name="competitorUrl" id="competitorUrl" className="form-control cl_contact cl_subject"
                      placeholder="Add Competitor URLs one per line" value={competitorUrl} onChange={(e) => handleChange(e)} /> : ""}
                    {/* {select === "Free Trial Request" ? <span className='text-error'>{validation.competitorUrl}</span> : ""} */}
                  </div>
                </div>
                {/* <div className="col-xl-12 col-lg-12 col-md-12 ">
                <div className="form-group">
                  <textarea type="text" className="form-control textarea" id="select_comp_box" placeholder="Add Competitor URLs one per line" name="competitor_url" defaultValue={""} />
                </div>
              </div> */}
                <div className="col-xl-12 col-lg-12 col-md-12 ">
                  <div className="form-group">
                    <textarea type="text" className="form-control textarea" id="message" placeholder="Your Message *" name="message"
                      value={message} onChange={(e) => handleChange(e)} />
                    <span className='text-error'>{validation.message}</span>
                    {/*                 
                  <input type="text" class="form-control"id="message" placeholder="Your Message *" name="message" required>
                 */}
                  </div>
                </div>
                <div className="form-element-section contact_full contact_us_terms_condition">
                  <label htmlFor="fm_privacy_policy6" className="ctform-label privacy-unchecked">
                    <input id="fm_privacy_policy6" name="checkbox" className="wd-flex-row fm-gdpr-checkbox checkbox3" type="checkbox"
                      value={checkBox} onClick={callCheckFunction} />
                    I consent to have this website store my submitted information so they can respond to my inquiry (<HashLink to="/terms-conditions/" target="_blank">Terms &amp; Conditions</HashLink> and  <HashLink to="/privacy-policy/" target="_blank">Privacy Policy</HashLink>)</label>
                  <span className='text-err checkbox_cus'>{validation.checkbox}</span>
                </div>
                <div className="pt-4 recaptcha-checkbox">
                  <ReCAPTCHA
                    sitekey="6LdkrQwkAAAAAOGkFoji7cLJxhE9NnOVSZEI_GEf"
                    name="recaptcha"
                    className="recaptcha-checkbox"
                    value={captcha}
                    onChange={checkCaptcha}
                    align='center'
                  />
                  <span className='text-err recaptcha'>{validation.recaptcha}</span>
                </div>
              </div>
              <div className="common_center contact_full">
                <button type="submit" className="contact_form_submit_btn_wrap">
                    {loading && <span>Submit</span>}
                    {loading && (<i className="fa fa-spinner fa-spin" />)}
                    {!loading && <span>Submit</span>}</button>
              </div>
            </form></div>
          <input className="ohnohoney" autoComplete="off" type="text" id="first_name" name="first_name" placeholder="Your name here" disabled />
          <input className="ohnohoney" autoComplete="off" type="text" id="cmp_site_url" name="cmp_site_url" placeholder="Your company url here" disabled />
        </div>
        {/* End Contact Form */}
        {/* CTA */}
        <div className="cta_bg_wrap">
          <div className="cta_bg_img_left_outer_wrap">
            <img src={bg_4} alt="CTA_bg" />
          </div>
          <div className="cta_bg_img_right_outer_wrap">
            <img src={bg_3} alt="CTA_bg" />
          </div>
          <div className="container">
            <div className="cta_content_outer_wrap">
              <h4><span>Let’s Get Started,</span><br />Contact The Experts <br />And Boost Your SEO Today!</h4>
              {/*  <a href="#">Request Free Trial</a> */}
            </div>
          </div>
        </div>
        <link rel="stylesheet" href="https://www.elitesiteoptimizer.com/public/assets/plugin/select2/css/select2.min.css" type="text/css" media="all" />
        {/* #et-main-area */}
        {/* <div className="main-addon hide-overlay">
          <div className="overlay">
            <div className="overlay-content">
              <h2><button className="supportBTN" style={{ float: 'right' }}>x</button></h2>
              <iframe title="support" src={`${process.env.REACT_APP_DOMAIN}/addon/kb/`}  />
            </div>
          </div>
          <div className="buttons-container">
            <button className="button supportBTN"><i className="fa fa-search" />Support</button>
          </div>
        </div>  */}
        {/*Start of Zendesk Chat Script*/}
        {/*End of Zendesk Chat Script*/}{/*'}'*/}
        {/* #main-footer */}
        {/* #page-container */}
      </div>
    );
  }

export default ContactUs;