import React, { useState, useRef, useEffect } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import ReCAPTCHA from "react-google-recaptcha";
import './FeaturesForm.css';
import $ from 'jquery';
import { HashLink } from 'react-router-hash-link';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const FeaturesForm = () => {
    const [value, setValue] = useState()
    // const [isVerified, setIsVerified] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    const Ref = useRef(null);

    const FeaturesURL = `${process.env.REACT_APP_DOMAIN}/call-contact-form-api`;

    const navigate = useNavigate();

    const [checkBox, setCheckBox] = useState(false)
    const [captcha, setCaptcha] = useState(false)
    const [loading, setLoading] = useState(false)

    const callCheckFunction = () => {
        setCheckBox(!checkBox)
    }

    const checkCaptcha = () => {
        setCaptcha(true)
    }

    const [inputValues, setInputValue] = useState({
        name: "",
        email: "",
        phoneNo: "",
        others: "",
        websiteUrl: "",
        message: "",

    });

    const { name, email, phoneNo, message } = inputValues

    const [validation, setValidation] = useState({
        name: "",
        email: "",
        phoneNo: "",
        others: "",
        message: "",
        checkbox: "",
        recaptcha: ""
    });

    const checkValidation = () => {
        let errors = { ...validation };

        //Name validation
        if ($('#name').val() == "") {
            errors.name = "This field is required.";
        } else if (!(/^[a-zA-Z]+$/g.test($('#name').val()))) {
            errors.name = "Please enter alphabet character only";
        } else {
            errors.name = "";
        }

        //Email validation
        if ($('#email').val() == "") {
            errors.email = "This field is required.";
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test($('#email').val())) {
            errors.email = "Please enter valid Email";
        } else {
            errors.email = "";
        }

        //Phone validation
        if ($('#phoneNo').val() === "") {
            errors.phoneNo = "This field is required.";
        }
        else if ($('#phoneNo').val().length < 10 || $('#phoneNo').val().length >= 15) {
            errors.phoneNo = "Please enter valid Phone number";
        }
        else {
            errors.phoneNo = "";
        }

        //Website URL Validation 
        // if ($('#url').val() === "") {
        //     errors.websiteUrl = "This field is required.";
        // } else if (!/^(https?|s?ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test($('#url').val())) {
        //     errors.websiteUrl = "Please enter a valid URL starting with http:// or https://";
        // } else {
        //     errors.websiteUrl = "";
        // }

        //Message validation
        if ($('#message').val() == "") {
            errors.message = "This field is required.";
        } else {
            errors.message = "";
        }

        //checkbox validation
        if (checkBox) {
            errors.checkbox = "";
        } else {
            errors.checkbox = "This field is required.";
        }

        //recaptcha validation
        if (captcha) {
            errors.recaptcha = "";
        } else {
            errors.recaptcha = "This field is required.";
        }

        return setValidation(errors);
    }

    // const onChange = (value) => {
    //     console.log("Captcha value:", value);
    //     setIsVerified({ isVerified: true })
    // }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setInputValue({ ...inputValues, [name]: value });
        // checkValidation();
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        Ref.current.focus();
        checkValidation();
        // console.log("input-----", inputValues);
        if (name != '' && email != '' && phoneNo != '' && message != '' && checkBox && captcha) {
            setLoading(true)
            await axios.post(FeaturesURL, {
                email: inputValues.email,
                url: inputValues.websiteUrl,
                name: inputValues.name,
                phone: value + " " + inputValues.phoneNo,
                // subject: "test",
                message: inputValues.message,
                competitor_url: ""
            })
                .then((response) => {
                    console.log("Get.Data=====>", response)
                    //redirect logic
                    if (response.data.result == 200) {
                        navigate("/thankyou")
                    }
                })
                .catch(error => { console.log(error) })
            setLoading(false)
        } else {
            console.log("unfilled spaces, thows error")
        }

    }


    return (
        <div style={{width: '100%', padding: 'auto', position: 'sticky', display: 'inherit'}} className="seo_form_outer_wrap">
            <form className="needs-validation" noValidate id="contact_form" onSubmit={handleSubmit} autoComplete="off">
                {/* <input type="hidden" name="_token" defaultValue="PvZStfFhzobq472HDXSipAlJkHxfqp0aB6oaYlzF" />
                <input className="ohnohoney" autoComplete="off" type="text" id="first_name" name="first_name" placeholder="Your name here" /> */}
                <h3>Get In Touch With Our <span>SEO Experts</span> Today</h3>
                <input type="text_cus" className="cl_contact clyour_name" id="name" placeholder="Your Name *" name="name"
                    onChange={(e) => handleChange(e)}
                    value={inputValues.name} ref={Ref} />
                <span className='text-errors'>{validation.name}</span>

                <input type="email" className="cl_contact cl_email" id="email" placeholder="Your Email *" name="email"
                    onChange={(e) => handleChange(e)}
                    value={inputValues.email} />
                <span className='text-errors'>{validation.email}</span>

                <div className='flag-phone-number-field'>
                    <PhoneInput
                        international
                        defaultCountry="US"
                        value={value}
                        onChange={setValue}
                        className="flag-icon1"
                    />
                    <input type="number" className=" cl_contact cl_email" id="phoneNo" placeholder="Phone Number *" name="phoneNo"
                        value={inputValues.phoneNo} onChange={(e) => handleChange(e)} />
                </div>
                <span className='text-errors'>{validation.phoneNo}</span>

                <select className="select_comp" required name="subject">
                    <option value="Others" selected="&quot;selected&quot;">Others</option>
                    <option value="Subscription Request">Subscription Request</option>
                    <option value="Free Trial Request">Free Trial Request</option>
                    <option value=" Free Audit Request"> Free Audit Request</option>
                </select>
                <input type="url" name="websiteUrl" className=" cl_contact cl_subject" id="url" placeholder="https://example.com" value={inputValues.websiteUrl} onChange={(e) => handleChange(e)} />
                {/* <span className='text-errors'>{validation.websiteUrl}</span> */}
                <div className="form-group cl_contact cl_message">
                    <textarea type="text" className=" textarea" id="message" placeholder="Your Message *" name="message"
                        onChange={(e) => handleChange(e)}
                        value={inputValues.message} />
                    <span className='text-errors'>{validation.message}</span>
                </div>
                <div className="form-element-section seo_terms_check_box_outer_wrap">
                    {/* <input className="ohnohoney" autoComplete="off" type="text" id="cmp_site_url" name="cmp_site_url" placeholder="Your company url here" /> */}
                    <label htmlFor="fm_privacy_policy6" className="ctform-label privacy-unchecked" style={{ fontSize: '13.5px', lineHeight: '1.5', color: "white" }}>
                        <input id="fm_privacy_policy6" name="checkbox" className="wd-flex-row fm-gdpr-checkbox checkbox" type="checkbox" style={{ float: 'left', marginRight: '4px', marginTop: '4px' }}
                            value={checkBox} onClick={callCheckFunction} />
                        I consent to have this website store my submitted information so they can respond to my inquiry (<HashLink to="/terms-conditions/" target="_blank" style={{ display: 'unset' }}>Terms &amp; Conditions</HashLink> and
                        <HashLink to="/privacy-policy/" target="_blank" style={{ display: 'unset' }}>Privacy Policy</HashLink>)</label>
                    <div id="fm_privacy_policy6-error" />
                    <span className='text-errors checkbox1'>{validation.checkbox}</span>
                </div>
                {/* <div className="col-xl-12 col-lg-12 col-md-12">
                    <center style={{ marginTop: '5px' }}>
                        {/* <div id="recaptcha" style="width: 100% !important;" class="g-recaptcha" data-sitekey="6LflX64cAAAAAAiR4Iy9PWSv4kc-j_LrJGEzuRK4"></div> 
                        <div id="recaptcha" className="g-recaptcha" data-sitekey="6LeLSd4cAAAAAE-POFEiQ9wWyXvSTykPyL-IbDIp" />
                        <span className="err-hide" id="err-recaptcha" />
                        <label id="captacha-error" style={{ display: 'none' }}>This field is required.</label>
                    </center>
                </div> */}
                <div className='mt-2'>
                    <ReCAPTCHA
                        sitekey="6LdkrQwkAAAAAOGkFoji7cLJxhE9NnOVSZEI_GEf"
                        value={captcha}
                        onChange={checkCaptcha}
                        name="recaptcha"
                        className='recaptcha-checkbox'
                        align="center"
                    />
                    <span className='text-errors recaptcha1'>{validation.recaptcha}</span>
                </div>
                <button type="submit">
                    {loading && <span>Submit</span>}
                    {loading && (<i className="fa fa-spinner fa-spin" />)}
                    {!loading && <span>Submit</span>}
                </button>
            </form>
        </div>
    )
}

export default FeaturesForm;