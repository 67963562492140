import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import axios from 'axios';
import parse from 'html-react-parser';
import CookiesPopup from '../../components/CookiesPopup/CookiesPopup';
import Bg4 from './../../images/eso/home_image/bg_4.png';
import Bg3 from './../../images/eso/home_image/bg_3.png';
import Startcircle from './../../images/eso/home_image/start_circle.png';
import Ecommercesericeimg from './../../images/eso/home_image/ecommerce_serice_img.png';

const DynamicSolutions = () => {

    let { id } = useParams()
    
    const [pageData, setPageData] = useState([])
    const [loading, setLoading] = useState(false)

    const callPageDataApi  = async () => {
        window.scroll(0, 0)
        setLoading(true)
        
          const dataParams = {
            token: `${process.env.REACT_APP_STRAPI_AUTH_TOKEN}`,
            id: id
          }
          await axios.post(`${process.env.REACT_APP_DOMAIN}/call-dynamic-solutions-page-data`, dataParams).then((res) => { setPageData(res.data) })
          setLoading(false)
      }
    
    
    useEffect(() => {
        callPageDataApi()
    }, [id])

    return (
        <div>

            {/* custom_css and Js  */}

            {/* #main-header */}

            { loading ? <i style={{alignItems: 'center',justifyContent: 'center',display: 'flex',fontSize: '35px',marginBottom: '50vw', marginTop: '20vw'}} className="fa fa-spinner" /> :<div id="et-main-area">
                {/* End Header */}
                {/* Page Title */}
                {pageData.map((items, index) => {
                    return (
                        <div className="container-fluid pl-0 pr-0 page_title_outer_wrap ecommerce_page_title_outer_wrap">
                            <div className="container">
                                <div className="page_title_img_wrap">
                                    <img src={`https://strapi.elitesiteoptimizer.com/${items.block_1.image.image_file.url}`} alt="Banner-Final" />
                                </div>
                                <h2>{items.title}</h2>

                                <h1>SEO Solutions To Grow Your Ecommerce Business</h1>
                            </div>
                        </div>
                    )
                })}

                {/* End Page Title */}
                {/* Ecommerce_detail */}
                {pageData.map((items, index) => {
                    return (
                        <div className="container">
                            <div className="ecommerce_content_details_outer_wrap">
                                <h3>{items.block_2.title.heading_text}</h3>
                                {(parse(items.block_2.description.text))}
                            </div>
                            <div className="ecommerce_start_service_wrap">
                                <div className="ecommerce_start_service_image_wrap">
                                    <img src={Ecommercesericeimg} alt="ecommerce_service_img" />
                                </div>
                                <div className="ecommerce_start_service_outer_wrap">
                                    {parse(items.block_4.description.text)}
                                    <div className="ecommerce_start_service_right_wrap">
                                        <HashLink to={items.block_4.button.link} elementId='root'>
                                            <img src={Startcircle} alt="start_circle" />
                                        </HashLink>
                                    </div>
                                </div>
                            </div>
                            {/* Help */}
                            <div className="ecommerce_faq_accordion_outer_wrap">
                                <h3>Check Out How Elite Site Optimizer Can Help <span>eCommerce Websites</span></h3>
                                <div className="seo_accordion_inner_wrap">
                                    <div className="accordion" id="ecommerce_faq_accordion">
                                        {items.block_3.map((subItem, subIndex) => {
                                            return (
                                                <div className="card">
                                                    <div className="card-header" id={`seo_accordion_${subItem.id}`}>
                                                        <h2 className="mb-0">
                                                            <button type="button" className="btn btn-link" data-toggle="collapse" data-target={`#ecommerce_faq_accordion_collapse_${subItem.id}`}><i className="eso_icon eso_Plus_icon" />{subItem.title.heading_text}</button>
                                                        </h2>
                                                    </div>
                                                    <div id={`ecommerce_faq_accordion_collapse_${subItem.id}`} className="collapse" aria-labelledby={`seo_accordion_${subItem.id}`} data-parent="#ecommerce_faq_accordion">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-xl-12 col-lg-12">
                                                                    {(parse(subItem.description.text))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}

                {/* End */}
                {/* CTA */}
                <div className="cta_bg_wrap">
                    <div className="cta_bg_img_left_outer_wrap">
                        <img src={Bg4} alt="CTA_bg" />
                    </div>
                    <div className="cta_bg_img_right_outer_wrap">
                        <img src={Bg3} alt="CTA_bg" />
                    </div>
                    <div className="container">
                        <div className="cta_content_outer_wrap">
                            <h4><span>Let’s Get Started</span><br /> Contact The Experts And Boost<br /> Your SEO Today!</h4>
                            <div className="cta_quote_outer_wrap solution-cta">
                                <HashLink to="/contact-us" elementId='root' className="btn_white_color_wrap">Consult our SEO Experts</HashLink>
                                <HashLink to="/contact-us/?subject=free-trial-request" elementId='root'>Request Free Trial</HashLink>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End CTA */}
            </div> }
            {/* #et-main-area */}
            <div className="main-addon hide-overlay">
                <div className="overlay">
                    <div className="overlay-content">
                        <h2><button className="supportBTN" style={{ float: 'right' }}>x</button></h2>
                        <iframe title="support" src="https://www.elitesiteoptimizer.com/addon/kb/" />
                    </div>
                </div>
                <div className="buttons-container">
                    <button className="button supportBTN"><i className="fa fa-search" />Support</button>
                </div>
            </div>

            {/*Start of Zendesk Chat Script*/}
            {/*End of Zendesk Chat Script*/}
            {/* <div class="cta_bg_wrap">
    <div class="cta_bg_img_left_outer_wrap"> <img src="https://www.elitesiteoptimizer.com/public/assets/eso/images/home_image/bg_4.png" alt="CTA_bg"> </div>
    <div class="cta_bg_img_right_outer_wrap"> <img src="https://www.elitesiteoptimizer.com/public/assets/eso/images/home_image/bg_3.png" alt="CTA_bg"> </div>
    <div class="container">
        <div class="cta_content_outer_wrap">
            <h4><span>Schedule A Meeting</span> To Take<br>Your Business To The Next Level.</h4> <a href="#">Get Your Free Trial</a> </div>
    </div>
</div> */}
            <CookiesPopup />
            {/* #main-footer */}
            {/* #page-container */}
        </div>
    )
}

export default DynamicSolutions;