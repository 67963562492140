import React, { useEffect } from 'react';
// import FormComponent from './../../../../components/FormComponent/FormComponent';
import Form from './../../Form/Form'
import { HashLink } from 'react-router-hash-link';
import servicebanner from './../../../../images/eso/home_image/service_banner.png';
import feature_img_1 from './../../../../images/eso/features/feature_img_1.png';
import feature_img_2 from './../../../../images/eso/features/feature_img_2.png';
import feature_img_3 from './../../../../images/eso/features/feature_img_3.png';
import feature_img_4 from './../../../../images/eso/features/feature_img_4.png';
import feature_img_5 from './../../../../images/eso/features/feature_img_5.png';
import alberto_161765dff2 from './../../../../images/str/uploads/alberto_161765dff2.png';
import gothiclogo_54d4bf7a89 from './../../../../images/str/uploads/gothiclogo_54d4bf7a89.png';
import candlsaddles_9409532d67 from './../../../../images/str/uploads/candlsaddles_9409532d67.png';
import runnershop_9439ea6f4f from './../../../../images/str/uploads/runnershop_9439ea6f4f.png';
import laura_8af43ee99a from './../../../../images/str/uploads/laura_8af43ee99a.jpeg';
import contact_services_bc4e80e326 from './../../../../images/str/uploads/contact_services_bc4e80e326.jpeg';
import mbdecor_6f6ba94668 from './../../../../images/str/uploads/mbdecor_6f6ba94668.jpeg';
import DigitalMarketingCarousel from './../../../../components/Carousel/DigitalMarketingCarousel'
import './digitalmarketing.css';


const DigitalMarketing = () => {
    //Title Change
    useEffect(() => {
        document.title = 'Digital Marketing Services | Performance Based SEO | Mobile SEO';
    }, []);

    return (
        <div id="et-main-area">
            <div className="container-fluid pl-0 pr-0 page_title_outer_wrap">
                <div className="container">
                    <h2>Digital Marketing</h2>
                    <h1>Logic and techniques specific to different kinds of businesses</h1>
                </div>
            </div>
            <div className="solution_info_bg_wrap">
                <div className="container">
                    {/* Feature Info */}
                    <div className="row features_info_row_wrap">
                        <div className="col-xl-6 col-lg-6 col-md-7">
                            <div className="features_info_content_wrap">
                                <h3>Digital Marketing Services</h3>
                                <p>Digital marketing is not a game of chance but a service that involves clear logic and techniques specific to different kinds of businesses. We understand that tools have their purpose while services have theirs. Delivering marketing services involves a great deal of business analysis. We have categorized our digital marketing services into three broad categories</p>
                                <ul className="arrow_listing_outer_wrap">
                                    <li><HashLink to="/services/digital-marketing-services/performance-based-seo/" elementId='root' target="_blank" rel="noopener">Performance-Based SEO Services</HashLink></li>
                                    <li><HashLink to="/services/digital-marketing-services/mobile-seo/" target="_blank" elementId='root' rel="noopener">Mobile SEO Services</HashLink></li>
                                    <li><HashLink to="/services/digital-marketing-services/app-store-optimization/" elementId='root' target="_blank" rel="noopener">App Store Optimization</HashLink></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-5">
                            <div className="feature_info_img_outer_wrap solutions_featured_image_outer_wrap digital_featured_img_wrap"><img src={servicebanner} alt="digital marketing service banner" /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container digital_utilize_outer_wrap">
                {/* Listing Features gird  */}
                <h3>Our digital marketing services utilize the following factors to give you a strong online presence that has a clear impact on your target visitors</h3>
                <div className="feature_listing_outer_wrap">
                    {/* Feature 1 */}
                    <div className="feature_listing_single_wrap">
                        <div className="feature_listing_img_outer_wrap"><img src={feature_img_1} alt="features listing image 1" /></div>
                        <div className="feature_listing_content_wrap">
                            <h4>Analytics and Review</h4>
                            <p>Whether it’s using device learning algorithms to predict how likely a site visitor is to convert, or preparing an audience analysis deep dive, our team models, researches, and builds products that make our client teams and campaigns smarter.</p>
                        </div>
                    </div>
                    <div className="feature_listing_single_wrap">
                        <div className="feature_listing_img_outer_wrap"><img src={feature_img_2} alt="features listing image 2" /></div>
                        <div className="feature_listing_content_wrap">
                            <h4>Identifying key opportunities</h4>
                            <p>Our experts provide guidance on the best practices to create marketing opportunities. They present insights quickly and prioritize critical issues so their key recommendations can be acted upon.</p>
                        </div>
                    </div>
                    <div className="feature_listing_single_wrap">
                        <div className="feature_listing_img_outer_wrap"><img src={feature_img_3} alt="features listing image 3" /></div>
                        <div className="feature_listing_content_wrap">
                            <h4>Identifying your goals</h4>
                            <p>Outlining a set of digital marketing goals creates a variety of measures to help establish review, and command performance across all digital marketing activities. Our experts have the ability to fully understand your business goals so they can work to achieve them.</p>
                        </div>
                    </div>
                    <div className="feature_listing_single_wrap">
                        <div className="feature_listing_img_outer_wrap"><img src={feature_img_4} alt="features listing image 4"/></div>
                        <div className="feature_listing_content_wrap">
                            <h4>Executing your digital strategy</h4>
                            <p>Successful online planning isn’t simply about getting eyeballs. It’s about getting the best ones to notice. Our digital media planning and buying team figures out how, when, and where to reach consumers in the most relevant environments for our clients.</p>
                        </div>
                    </div>
                    <div className="feature_listing_single_wrap">
                        <div className="feature_listing_img_outer_wrap"><img src={feature_img_5}  alt="features listing image 5" /></div>
                        <div className="feature_listing_content_wrap">
                            <h4>Developing Online Initiatives</h4>
                            <p>Our team is responsible for directing traffic to your site to drive online sales and increase ROI via all known forms of digital marketing. We focus primarily on SEM, Paid campaigns, SEO, Re-targeting, Paid Social, Local Digital Marketing, and Content Marketing.</p>
                        </div>
                    </div>
                </div>
                {/* End */}</div>
            {/*  <div className="container">
                <div className="our_client_says_outer_wrap our_client_says_service_wrap">
                    <h2>What Our Client</h2>
                    <h3>Says About Us</h3>
                    <div className="our_client_say_row_wrap">
                        <div className="our_client_says_slider_outer_wrap">
                            <div className="our_client_says_single_wrap">
                                <div className="client_image_outer_wrap">
                                    <img src={alberto_161765dff2} alt="alberto" />
                                </div>
                                <div className="client_feedback_content_wrap">
                                    <h4>Very professional team, work successfully with strong collaboration, they faced all the problems promptly addressing, I hope to work with them in the next project.</h4>
                                    <h5>lberto. M</h5>
                                    <h4 className="author-bio"><span className="item-company">VENTUNO</span>, <span className="author-location">S.R.L</span></h4>
                                </div>
                            </div>
                            <div className="our_client_says_single_wrap">
                                <div className="client_image_outer_wrap">
                                    <img src={gothiclogo_54d4bf7a89} alt="gothiclogo" />
                                </div>
                                <div className="client_feedback_content_wrap">
                                    <h4>eGrove Systems did a great job adding onto our first project. I kept having different thoughts on how I needed my module to work and they did great adapting. Communication was very quick and the developers were readily available. I have more projects down the line and I will keep in touch when I am ready.</h4>
                                    <h5>Wes Stefanko</h5>
                                    <h4 className="author-bio"><span className="item-company">Gothic Jewelry</span></h4>
                                </div>
                            </div>
                            <div className="our_client_says_single_wrap">
                                <div className="client_image_outer_wrap">
                                    <img src={candlsaddles_9409532d67} alt="candlsaddles" />
                                </div>
                                <div className="client_feedback_content_wrap">
                                    <h4>Thank you eGrove Systems for the great work on my website! Our team has been very pleased to work with you over the past couple of weeks. This was my second project with eGrove Systems and I have nothing but good to say, I will be working with them in the future if the need arises. Thank you guys!</h4>
                                    <h5>Josiah McCampbell</h5>
                                    <h4 className="author-bio"><span className="item-company">Tech Fire Studio</span></h4>
                                </div>
                            </div>
                            <div className="our_client_says_single_wrap">
                                <div className="client_image_outer_wrap">
                                    <img src={runnershop_9439ea6f4f} alt="runnershop" />
                                </div>
                                <div className="client_feedback_content_wrap">
                                    <h4>Very happy with the result of this website build – the costs increased throughout the project but we were able to explain and the team helped us resolve everything without the charge in the end. Will definitely be working with them again!</h4>
                                    <h5>Erin</h5>
                                    <h4 className="author-bio"><span className="item-company">The Runner Shop</span></h4>
                                </div>
                            </div>
                            <div className="our_client_says_single_wrap">
                                <div className="client_image_outer_wrap">
                                    <img src={laura_8af43ee99a} alt="laura" />
                                </div>
                                <div className="client_feedback_content_wrap">
                                    <h4>I am very pleased with the results from eGrove Systems. They are very professional and answered my concerns on time. They are very knowledgeable with the subject and need little instructions on how to perform the job. I will definitely hire them again in the future.</h4>
                                    <h5>Laura</h5>
                                    <h4 className="author-bio"><span className="item-company">www.petiteperfectfit.com</span></h4>
                                </div>
                            </div>
                            <div className="our_client_says_single_wrap">
                                <div className="client_image_outer_wrap">
                                    <img src={contact_services_bc4e80e326} alt="contact_services" />
                                </div>
                                <div className="client_feedback_content_wrap">
                                    <h4>Fantastic!! I have gone through this process several times with your team and my previous programmer in the past year or two. Your teams have been much better than my last programmer and I’ve always been happy with the final project. I have to say, this particular project has been a very smooth one, every step of the way!</h4>
                                    <h5>Scott Jenkins</h5>
                                    <h4 className="author-bio"><span className="item-company">Owner, Contact Services, L.L.C</span></h4>
                                </div>
                            </div>
                            <div className="our_client_says_single_wrap">
                                <div className="client_image_outer_wrap">
                                    <img src={mbdecor_6f6ba94668} alt="mbdecor" />
                                </div>
                                <div className="client_feedback_content_wrap">
                                    <h4>I would like to thank you (eGrove Systems) and your team for persisting with it and getting it over the line. Due to the complexity of design a room it led to many delays. This is without a doubt, the longest project i have ever had on for a client – and i am really happy it is finally over! So thanks for your work.</h4>
                                    <h5>Chris Rutherford</h5>
                                    <h4 className="author-bio"><span className="item-company">Metro Radio, UK</span></h4>
                                </div>
                            </div>
                        </div>
                        <div className="client_feed_dots_wrap">
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="container">
                <div className='row' style={{justifyContent:'center'}}>
                    <div className='col-md-8 col-10'>
                    <DigitalMarketingCarousel />
                    </div>
                    </div>
            </div>
            {/* Form Start */}
            <Form />
            {/* Form End */}
            {/* #et-main-area */}
            <div className="main-addon hide-overlay">
                <div className="overlay">
                    <div className="overlay-content">
                        <h2><button className="supportBTN" style={{ float: 'right' }}>x</button></h2>
                        <iframe title="support" src="https://www.elitesiteoptimizer.com/addon/kb/" />
                    </div>
                </div>
                <div className="buttons-container">
                    <button className="button supportBTN"><i className="fa fa-search" />Support</button>
                </div>
            </div>
            {/*Start of Zendesk Chat Script*/}
            {/*End of Zendesk Chat Script*/}{/*'}'*/}
            {/* <div class="cta_bg_wrap">
<div class="cta_bg_img_left_outer_wrap"> <img src="https://www.elitesiteoptimizer.com/public/assets/eso/images/home_image/bg_4.png" alt="CTA_bg"> </div>
<div class="cta_bg_img_right_outer_wrap"> <img src="https://www.elitesiteoptimizer.com/public/assets/eso/images/home_image/bg_3.png" alt="CTA_bg"> </div>
<div class="container">
    <div class="cta_content_outer_wrap">
        <h4><span>Schedule A Meeting</span> To Take<br>Your Business To The Next Level.</h4> <a href="#">Get Your Free Trial</a> </div>
</div>
</div> */}
        </div>
    )
}

export default DigitalMarketing;