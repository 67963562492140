import React, { useState, useEffect } from 'react';
import axios from 'axios';
import parse from 'html-react-parser';
import { HashLink } from 'react-router-hash-link';
import { Link, useParams } from "react-router-dom";
import './loaderstyles.css';

const HottestTopics = () => {
    const [token, setToken] = useState()
    const [countOne, setCountOne] = useState(0)
    const [countTwo, setCountTwo] = useState(6)
    const [hottestTopics, setHottestTopics] = useState([])
    const [isLoading, setLoading] = useState(false)

    let { id } = useParams();

    const apiUrl = `/auth/local`;
    const callHottestTopics = `${process.env.REACT_APP_BLOGS_API}/blogs?publish_status=Publish&hottest_topic=true&_sort=date_time_publish:DESC&_start=${countOne}&_limit=${countTwo}`

    const dataParams = {
        identifier: 'admin',
        password: 'admin@123!'
    }

    const getHottestTopicData = async (e) => {
       
        setLoading(true)
   
          const dataParams = {
            token: `${process.env.REACT_APP_STRAPI_AUTH_TOKEN}`,
            id: id
          }
          axios.post(`${process.env.REACT_APP_DOMAIN}/call-hottest-pick-blogs`, dataParams).then((res) => { setHottestTopics(res.data) })
          setLoading(false)
      
      }

    useEffect(() => {
        getHottestTopicData();
    }, [])

    const renderHottestTopic = (
        <div className="row bgs-posts">
            {hottestTopics.slice(0, 6).map((items, index) => {
                let rawdate = new Date(items.created_at)   
                
                let date = rawdate.getDate()
                let month = rawdate.toLocaleString('default', { month: 'long' })
                let year = rawdate.getFullYear()
                return (
                    <div className="col-lg-4 col-md-6 col-sm-12 layout-simple">
                        <article id={items.id} className width="400px">
                            <HashLink elementId='root' className="bnt-featured-image-url" to={`/blogs/${items.url_slug}`}>
                                <img src={`https://strapi.elitesiteoptimizer.com/${items.featured_image.image_file.formats.small.url}`} alt={items.alt_text} />
                            </HashLink>
                           
                            <div className="desc_content">
                                <h2 id='card-title' className="bnt-title h3"><Link to={`/blogs/${items.url_slug}`} state={{ from: `${items.url_slug}` }}>{items.title}</Link></h2>

                                <p className="post-meta">  
                                    <span className="published">{`${month} ${date}, ${year}`}</span> 
                                    {/* <span className="meta-item meta-views">{items.views === null ? 0 : items.views} Views</span>
                                    | */}
                                    <div className="bnt-category" style={{marginTop:'7px'}}> 
                                        {items.categories.map((subItem, subIndex) => { return <span><Link to={`/blogs/topics/${subItem.url_slug}`} state={{ from: `${subItem.url_slug}` }} style={{ border: 'none' }} className="blog_categ" rel="category tag" >{subItem.name}</Link>&nbsp;&nbsp;</span> })}
                                    </div>
                                </p>
                                <div className="bnt-content-cus">							
                                <p className='short-description' style={{ fontSize: '14px' }}>{parse(items.page_content)}</p>
                                </div>
                            </div>
                        </article>
                    </div>
                )
            })}
        </div>
    )

    return (
        <div>
            {isLoading ? <i className="fa fa-spinner" /> : renderHottestTopic}
        </div>
    )
}

export default HottestTopics;